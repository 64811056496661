<script setup lang="ts">
import type { CandlestickData, ChartOptions, DeepPartial, IChartApi, ISeriesApi, ITimeScaleApi, SeriesMarker, Time } from 'lightweight-charts'
import { ColorType, CrosshairMode, LineStyle, createChart } from 'lightweight-charts'
import BN from 'bignumber.js'
import type { KLineUnit } from '@/store/ws'

const REFRESH_MIN_ITEM_COUNT = 30

interface Current {
  open?: number
  high?: number
  low?: number
  close?: number
  time: number
  amount?: number
  quoteAmount?: number
  color?: string
}
let chart: IChartApi
let chart2: IChartApi
let candlestickSeries: ISeriesApi<'Candlestick'>
let histogramSeries: ISeriesApi<'Histogram'>
let timeScale: ITimeScaleApi<Time>
let priceLines: any[]
const { kLineList, kLineUnit, subscribeSymbolKLine, patchKLine } = useWs()
const { currentSymbol, currentSymbolItem, tickerInfo, currentTickerItem } = useSymbol()
const { currentOpenOrderList, orderHistoryList, getOrderHistory } = useOrder()
const { isSigned } = useKey()
const { isMobile } = useResponsive()
const dataList = ref<CandlestickData[]>([])
const current = ref<Current>()
const isFetch = ref(false)
const isFirstFetch = ref(true)
const chartSetting = useStorage('chartSetting', {
  showOpenOrder: true,
  showOrderHistory: true,
})
const isHairMove = ref(true)

const { run: getKLine } = useHttp(vesselApi.public.getKLine)
const currentPricePrecision = computed(() => +currentTickerItem.value?.quoteAssetPrecision - +currentTickerItem.value?.baseAssetPrecision)

const unitOptions: { value: KLineUnit, label: string, gap: number }[] = [
  { value: '1m', label: '1m', gap: 60 },
  { value: '3m', label: '3m', gap: 3 * 60 },
  { value: '5m', label: '5m', gap: 5 * 60 },
  { value: '15m', label: '15m', gap: 15 * 60 },
  { value: '30m', label: '30m', gap: 30 * 60 },
  { value: '1h', label: '1H', gap: 1 * 60 * 60 },
  { value: '2h', label: '2H', gap: 2 * 60 * 60 },
  { value: '4h', label: '4H', gap: 4 * 60 * 60 },
  { value: '8h', label: '8H', gap: 8 * 60 * 60 },
  { value: '12h', label: '12H', gap: 12 * 60 * 60 },
  { value: '1d', label: '1D', gap: 24 * 60 * 60 },
]

const dateFormatter: Record<KLineUnit, string> = {
  '1d': 'MM DD',
  '12h': 'HH:mm',
  '8h': 'HH:mm',
  '4h': 'HH:mm',
  '2h': 'HH:mm',
  '1h': 'HH:mm',
  '30m': 'HH:mm',
  '15m': 'HH:mm',
  '5m': 'HH:mm',
  '3m': 'HH:mm',
  '1m': 'HH:mm',
}

const chartOptions: DeepPartial<ChartOptions> = {
  rightPriceScale: {
    visible: true,
  },
  leftPriceScale: {
    visible: false,
  },
  layout: {
    textColor: '#ddd',
    background: {
      type: ColorType.Solid,
      color: '#141416',
    },

    fontSize: 14,
    fontFamily: '',
  },
  grid: {
    vertLines: { color: '#353945', style: 2 },
    horzLines: { color: '#353945', style: 2 },
    // vertLines: { visible: false },
    // horzLines: { visible: false },
  },
  crosshair: {
    // Change mode from default 'magnet' to 'normal'.
    // Allows the crosshair to move freely without snapping to datapoints
    mode: CrosshairMode.Normal,

    // Vertical crosshair line (showing Date in Label)
    vertLine: {
      color: '#E6E8EC',
      labelBackgroundColor: '#353945',
    },

    // Horizontal crosshair line (showing Price in Label)
    horzLine: {
      color: '#E6E8EC',
      labelBackgroundColor: '#353945',
    },
  },
  localization: {
    locale: 'en-US',
    timeFormatter: (time: Time) => {
      return dayjs(+time as number).format(kLineUnit.value === '1d' ? 'MM DD, YYYY' : 'MM DD, YYYY HH:mm')
    },
    priceFormatter: (price: any) => {
      return formatPrice(price, currentPricePrecision.value, true)
    },
  },
}

onMounted(() => {
  chart = createChart('container', chartOptions)
  chart2 = createChart('container2', chartOptions)
  candlestickSeries = chart.addCandlestickSeries({
    upColor: '#26a69a',
    downColor: '#ef5350',
    borderVisible: false,
    wickUpColor: '#26a69a',
    wickDownColor: '#ef5350',
    baseLineWidth: 2,
  })
  histogramSeries = chart2.addHistogramSeries({
    baseLineWidth: 2,
  })
  candlestickSeries.priceScale().applyOptions({
    // scaleMargins: {
    //   top: 0.1,
    //   bottom: 0.25,
    // },
    ticksVisible: true,
    minimumWidth: 90,
  })
  histogramSeries.priceScale().applyOptions({
    // scaleMargins: {
    //   top: 0.8,
    //   bottom: 0,
    // },
    ticksVisible: true,
    minimumWidth: 90,
  })
  histogramSeries.applyOptions({
    lastValueVisible: false,
    priceLineVisible: false,
  })
  candlestickSeries.applyOptions({
    lastValueVisible: false,
  })
  chart.timeScale().applyOptions({
    visible: false,
  })
  timeScale = chart2.timeScale()
  timeScale.applyOptions({
    // @ts-expect-error need this prop
    tickMarkFormatter: (time: Time) => {
      return dayjs(+time as number).format(dateFormatter[kLineUnit.value])
    },
  })
  timeScale.subscribeVisibleLogicalRangeChange((params) => {
    // console.log(params)
    if (params && params.from < REFRESH_MIN_ITEM_COUNT) {
      fetchFormerKLine()
    }
  })

  chart.subscribeCrosshairMove((param) => {
    const item = dataList.value.find(i => i.time === param.time)
    if (item) {
      current.value = item as Current
    }
    else {
      current.value = dataList.value.at(-1) as Current
    }
  })
  chart2.subscribeCrosshairMove((param) => {
    const item = dataList.value.find(i => i.time === param.time)
    if (item) {
      current.value = item as Current
    }
    else {
      current.value = dataList.value.at(-1) as Current
    }
  })

  function getCrosshairDataPoint(series: any, param: any) {
    if (!param.time) {
      return null
    }
    const dataPoint = param.seriesData.get(series)
    return dataPoint || null
  }

  function syncCrosshair(chart: any, series: any, dataPoint: any) {
    if (dataPoint) {
      chart.setCrosshairPosition(10000000, dataPoint.time, series)
    }
    else {
      chart.clearCrosshairPosition()
    }
  }
  chart.subscribeCrosshairMove((param) => {
    // console.log(param, isHairMove.value)

    if (!isHairMove.value) {
      return
    }
    const dataPoint = getCrosshairDataPoint(candlestickSeries, param)
    syncCrosshair(chart2, histogramSeries, dataPoint)
  })
  chart2.subscribeCrosshairMove((param) => {
    if (!isHairMove.value) {
      return
    }
    const dataPoint = getCrosshairDataPoint(histogramSeries, param)
    syncCrosshair(chart, candlestickSeries, dataPoint)
  })

  chart.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
    timeRange && chart2.timeScale().setVisibleLogicalRange(timeRange)
  })

  chart2.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
    timeRange && chart.timeScale().setVisibleLogicalRange(timeRange)
  })
  chart.resize(document.querySelector('#chartWrapper')?.clientWidth, document.querySelector('#container')?.clientHeight)
  chart2.resize(document.querySelector('#chartWrapper')?.clientWidth, document.querySelector('#container2')?.clientHeight)
  window.addEventListener('resize', () => {
    // chart.resize(document.querySelector('#container')?.clientWidth || 1000, document.querySelector('#container')?.clientHeight || 468)
    chart.resize(document.querySelector('#chartWrapper')?.clientWidth, document.querySelector('#container')?.clientHeight)
    chart2.resize(document.querySelector('#chartWrapper')?.clientWidth, document.querySelector('#container2')?.clientHeight)
  })
  // chart.resize(document.querySelector('#container')?.clientWidth || 1000, document.querySelector('#container')?.clientHeight || 468)
})

function formatPrice(value: string | number, fixed: string | number = 2, isString = false) {
  if (!value) {
    return '-'
  }
  const _fixed = Math.max(0, Number(fixed))
  return isString ? BN(value).decimalPlaces(_fixed, BN.ROUND_HALF_CEIL).toFixed(_fixed) : BN(value).decimalPlaces(_fixed, BN.ROUND_HALF_CEIL).toNumber()
}

watch([kLineList, chartSetting], (newVal, oldVal) => {
  if (chart) {
    if (kLineList.value.length) {
      isHairMove.value = false

      const data = kLineList.value.sort((i, j) => +i.openTime - +j.openTime)
        .filter((i, index) => !!+i.closePrice).map(i => ({
          open: formatPrice(i.openPrice, currentPricePrecision.value),
          high: formatPrice(i.highPrice, currentPricePrecision.value),
          low: formatPrice(i.lowPrice, currentPricePrecision.value),
          close: formatPrice(i.closePrice, currentPricePrecision.value),
          time: +i.openTime,
          closeTime: +i.closeTime,
          amount: formatPrice(i.volume, currentTickerItem.value.baseAssetPrecision),
          value: formatPrice(i.volume, currentTickerItem.value.quoteAssetPrecision),
          quoteAmount: formatPrice(i.quoteVolume, currentTickerItem.value.quoteAssetPrecision),
          color: +i.closePrice >= +i.openPrice ? 'rgba(88, 189, 125, 1)' : 'rgba(239, 68, 91, 1)',
          volumeColor: +i.closePrice >= +i.openPrice ? 'rgba(88, 189, 125, 0.5)' : 'rgba(239, 68, 91, 0.5)',
        }))

      let c = 'rgba(88, 189, 125, 1)'

      for (const i in data) {
        if (!data[i].amount) {
          data[i].color = c
        }
        else {
          c = data[i].color
        }
      }
      // @ts-expect-error ignore ignore ignore
      dataList.value = data
      current.value = data.at(-1) as Current
      // @ts-expect-error ignore ignore ignore
      candlestickSeries.setData(data)
      // console.log(data)
      // @ts-expect-error ignore ignore ignore
      histogramSeries.setData(data.map(i => ({ ...i, color: i.volumeColor })))

      // open orders
      priceLines && priceLines.forEach(i => candlestickSeries.removePriceLine(i))
      if (chartSetting.value.showOpenOrder) {
        priceLines = currentOpenOrderList.value.filter(i => i.symbol === currentSymbol.value).map((i) => {
          // console.log(i)
          return candlestickSeries.createPriceLine({
            price: +i.price,
            color: i.side === 'BUY' ? '#58BD7D' : '#EF445B',
            lineWidth: 1,
            lineStyle: LineStyle.Dashed,
            axisLabelVisible: true,
            title: `${i.side === 'BUY' ? 'Buy' : 'Sell'} ${i.origQty}`,
          })
        })
        const lastItem = data.at(-1)
        priceLines.push(
          candlestickSeries.createPriceLine({
            price: +lastItem.close,
            color: lastItem.color,
            lineWidth: 1,
            lineStyle: LineStyle.Dashed,
            axisLabelVisible: true,
          }),
        )
      }
      else {
        priceLines = []
      }

      // history
      const markers: SeriesMarker<Time>[] = []
      if (chartSetting.value.showOrderHistory) {
        for (const item of orderHistoryList.value.filter(i => i.symbol === currentSymbol.value).filter(i => +i.executedQty > 0)) {
          // console.log(item)
          const kLineItem = data.find(i => +i.time <= +item.workingTime && +i.closeTime > +item.workingTime)
          if (kLineItem) {
            markers.push({
              time: kLineItem.time as Time,
              position: item.side === 'BUY' ? 'belowBar' : 'aboveBar',
              color: item.side === 'BUY' ? '#58BD7D' : '#EF445B',
              shape: item.side === 'BUY' ? 'arrowUp' : 'arrowDown',
              text: `${item.side === 'BUY' ? 'Buy' : 'Sell'} ${item.executedQty}  @ ${+item.executedQty !== 0 ? formatNumber(+item.cumulativeQuoteQty / +item.executedQty, getPrecisionFromSymbol(item.symbol).price) : '-'}`,
            })
          }
        }
      }
      candlestickSeries.setMarkers(markers.sort((i, j) => +i.time - +j.time))
      setTimeout(() => {
        isHairMove.value = true
      }, 50)
    }
    else {
      const data: CandlestickData[] = []
      candlestickSeries.setData(data)
      histogramSeries.setData(data)
      current.value = null
      dataList.value = []
    }
  }
}, {
  immediate: true,
})

function refreshKLine() {
  if (!kLineUnit.value || !currentSymbol.value) {
    return
  }

  const timeGap = unitOptions.find(i => i.value === kLineUnit.value)?.gap
  const timestamp = new Date().getTime()

  isFetch.value = true
  getKLine({
    symbol: currentSymbol.value,
    interval: kLineUnit.value,
    startTime: timestamp - timeGap * 1000 * 199,
    endTime: timestamp,
  }).then((res) => {
    isFirstFetch.value = false
    if (res.config.params.interval === kLineUnit.value) {
      patchKLine(res.data.klines)
    }
    const showLength = 84
    // console.log(showLength, kLineList.value.length, kLineList.value)

    nextTick(() => {
      timeScale.setVisibleLogicalRange({ from: kLineList.value.length - showLength, to: kLineList.value.length + 12 })
      candlestickSeries.priceScale().applyOptions({ autoScale: true })
      histogramSeries.priceScale().applyOptions({ autoScale: true })
    })
  }).finally(() => {
    isFetch.value = false
  })
}

function fetchFormerKLine() {
  if (isFetch.value) {
    return
  }
  isFetch.value = true
  const timeGap = unitOptions.find(i => i.value === kLineUnit.value)?.gap
  const firstTime = +kLineList.value[0]?.closeTime || new Date().getTime()
  getKLine({
    symbol: currentSymbol.value,
    interval: kLineUnit.value,
    startTime: firstTime - timeGap * 1000 * 200,
    endTime: firstTime,
  }).then((res) => {
    isFirstFetch.value = false
    patchKLine(res.data.klines)
  }).finally(() => {
    isFetch.value = false
  })
}

watch([currentSymbol, kLineUnit], () => {
  try {
    kLineList.value = []
    subscribeSymbolKLine(currentSymbol.value, kLineUnit.value)
    refreshKLine()
  }
  catch (e: any) {
    console.log(e)
  }
}, {
  immediate: true,
})

const priceInfo = computed(() => [
  {
    label: 'O',
    value: formatPrice(current.value?.open, currentPricePrecision.value, true),
  },
  {
    label: 'H',
    value: formatPrice(current.value?.high, currentPricePrecision.value, true),
  },
  {
    label: 'L',
    value: formatPrice(current.value?.low, currentPricePrecision.value, true),
  },
  {
    label: 'C',
    value: formatPrice(current.value?.close, currentPricePrecision.value, true),
  },
  {
    label: 'Change',
    value: current.value?.open ? getRatio((current.value.close - current.value.open) / current.value.open) : '-',
  },
  {
    label: 'Range',
    value: current.value?.low ? getRatio((current.value.high - current.value.low) / current.value.low) : '-',
  },
])

const volumeInfo = computed(() => [
  {
    label: `Vol(${currentSymbolItem.value?.baseAssetName})`,
    value: formatPrice(current.value?.amount, currentTickerItem.value?.baseAssetPrecision, true),
  },
  {
    label: `Vol(${currentSymbolItem.value?.quoteAssetName})`,
    value: formatPrice(current.value?.quoteAmount, currentTickerItem.value?.quoteAssetPrecision, true),
  },
])

whenever(isSigned, () => {
  getOrderHistory()
}, {
  immediate: true,
})
</script>

<template>
  <div id="chartWrapper" class="relative flex flex-col gap-y-0.02 overflow-hidden">
    <!-- <div v-if="!isMobile" class="h-0.48 flex items-center bg-black1 px-0.1">
      Chart
    </div> -->

    <div class="h-0.48 flex flex-col justify-center gap-x-0.08 bg-black1 sm:pl-0.16">
      <div class="flex items-center gap-x-0.16">
        <!-- <div class="text-button2 text-grey1">
          Time
        </div> -->
        <v-tag label="1m" :selected="kLineUnit === '1m'" class="cursor-pointer" @click="kLineUnit = '1m'" />
        <v-tag label="15m" :selected="kLineUnit === '15m'" class="cursor-pointer" @click="kLineUnit = '15m'" />
        <v-tag label="1H" :selected="kLineUnit === '1h'" class="cursor-pointer" @click="kLineUnit = '1h'" />
        <v-tag label="4H" :selected="kLineUnit === '4h'" class="cursor-pointer" @click="kLineUnit = '4h'" />
        <v-tag label="1D" :selected="kLineUnit === '1d'" class="cursor-pointer" @click="kLineUnit = '1d'" />
        <v-tag
          v-if="!['1m', '15m', '1h', '4h', '1d'].includes(kLineUnit)"
          :label="kLineUnit.endsWith('m') ? kLineUnit : kLineUnit.toUpperCase()" class="cursor-pointer" selected
        />

        <n-popover trigger="hover" placement="bottom-start" style="--n-color: var(--vessel-color-black-2)">
          <template #trigger>
            <svg-back fill="#FCFCFD" class="rotate--90 outline-none transition-all" hover="rotate-90" />
          </template>
          <div class="grid grid-cols-5 gap-0.12 p-0.14">
            <div
              v-for="item in unitOptions" :key="item.value"
              class="h-0.24 w-0.5 flex-center cursor-pointer rd-full transition-all"
              :class="kLineUnit === item.value ? 'text-black1 bg-white2' : 'text-white2 hover:bg-black3'"
              @click="kLineUnit = item.value"
            >
              {{ item.label }}
            </div>
          </div>
        </n-popover>
        <n-popover trigger="hover" placement="bottom-start" style="--n-color: var(--vessel-color-black-2)">
          <template #trigger>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="outline-none">
              <path d="M6 0.99999C6 0.815895 5.85076 0.666656 5.66667 0.666656H4C2.89543 0.666656 2 1.56209 2 2.66666V9.66666C2 9.85075 2.14924 9.99999 2.33333 9.99999H5.66667C5.85076 9.99999 6 9.85075 6 9.66666V0.99999Z" fill="#777E91" />
              <path d="M2.33333 11.3333C2.14924 11.3333 2 11.4826 2 11.6667V13.3333C2 14.4379 2.89543 15.3333 4 15.3333H5.66667C5.85076 15.3333 6 15.1841 6 15V11.6667C6 11.4826 5.85076 11.3333 5.66667 11.3333H2.33333Z" fill="#777E91" />
              <path d="M7.33333 15C7.33333 15.1841 7.48257 15.3333 7.66667 15.3333H12C13.1046 15.3333 14 14.4379 14 13.3333V11.6667C14 11.4826 13.8508 11.3333 13.6667 11.3333H7.66667C7.48257 11.3333 7.33333 11.4826 7.33333 11.6667V15Z" fill="#777E91" />
              <path d="M14 9.66666C14 9.85075 13.8508 9.99999 13.6667 9.99999H7.66667C7.48257 9.99999 7.33333 9.85075 7.33333 9.66666V0.99999C7.33333 0.815895 7.48257 0.666656 7.66667 0.666656H8.66667C9.03486 0.666656 9.33333 0.965133 9.33333 1.33332V3.33332C9.33333 4.43789 10.2288 5.33332 11.3333 5.33332H13.3333C13.7015 5.33332 14 5.6318 14 5.99999V9.66666Z" fill="#777E91" />
              <path d="M11.3176 0.785034C10.9781 0.642649 10.666 0.965168 10.666 1.33336V3.33336C10.666 3.70155 10.9645 4.00002 11.3327 4.00002H13.3327C13.7009 4.00002 14.0234 3.68795 13.881 3.34841C13.8194 3.2014 13.7308 3.0648 13.6088 2.94283L11.7232 1.05722C11.6012 0.935251 11.4646 0.846678 11.3176 0.785034Z" fill="#777E91" />
            </svg>
          </template>
          <div class="w-1.6 flex flex-col gap-y-0.08">
            <div class="flex items-center justify-between">
              Open Orders
              <n-switch v-model:value="chartSetting.showOpenOrder" />
            </div>
            <div class="flex items-center justify-between">
              Order History
              <n-switch v-model:value="chartSetting.showOrderHistory" />
            </div>
          </div>
        </n-popover>
      </div>
    </div>

    <div class="h-full w-full flex-1 overflow-hidden">
      <svg-loading v-if="!kLineList.length && isFirstFetch" class="absolute left-1/2 top-1/2 z-51 -translate-x-1/2 -translate-y-1/2" />

      <div class="h-[70%]">
        <div
          class="scale(80) bg-red-400 absolute left-0 top-0.5 z-50 ml-0.16 w-1.8 flex flex-wrap origin-top-left scale-75 gap-0.08 overflow-hidden text-0.12 sm:top-0.6 sm:w-[85%] sm:scale-100 sm:gap-0.16"
          :style="{
            '--label-color': current?.color,
          }"
        >
          <div class="text-grey1">
            {{ dayjs(+current?.time).format('MM/DD/YYYY HH:mm:ss') }}
          </div>
          <div
            v-for="(item) in priceInfo" :key="item.label" class="flex items-center gap-x-0.08"
          >
            <div class="text-gray">
              {{ item.label }}
            </div>
            <div class="label-value">
              {{ item.value === '-' ? '0' : item.value }}
            </div>
          </div>
        </div>
        <div id="container" class="h-full w-full flex-1" />
      </div>
      <div class="relative h-[30%]">
        <div
          class="scale(80) bg-red-400 absolute left-0 top-0.05 z-50 ml-0.16 w-auto flex flex-nowrap origin-top-left scale-75 gap-0.08 overflow-hidden text-0.12 sm:top-0.1 sm:scale-100 sm:gap-0.16"
          :style="{
            '--label-color': current?.close > current?.open ? 'var(--vessel-color-green)' : 'var(--vessel-color-red)',
          }"
        >
          <div
            v-for="(item) in volumeInfo" :key="item.label" class="flex items-center gap-x-0.08"
          >
            <div class="text-gray">
              {{ item.label }}
            </div>
            <div class="label-value">
              {{ item.value === '-' ? '0' : item.value }}
            </div>
          </div>
        </div>
        <div id="container2" class="h-full w-full flex-1" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.label-value {
  color: var(--label-color)
}
</style>
