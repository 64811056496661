<script setup lang="ts">
const { interactionStatus } = useAirdrop()
const { openModal } = useModal()
const router = useRouter()
const openPanel = ref<number[]>([])
const hasInitStatus = ref(false)

function getStatus(id: number) {
  const item = interactionStatus.value.find(i => +i.id === +id)
  // const dependencyItem = interactionStatus.value.find(i => +i.id === +item.dependencyId)
  // return 1
  return item?.completed ? 1 : 3
}
function getIconStatus(id: number) {
  const items = interactionStatus.value.filter(i => +i.groupId === +id)
  if (items.every(i => i.completed)) {
    return 3
  }
  if (items.some((i) => {
    const dependencyItem = interactionStatus.value.find(j => +j.id === +i.dependencyId)
    return dependencyItem?.completed
  })) {
    return 2
  }
  return 1
}
function getScore(id: number) {
  return interactionStatus.value.find(i => +i.id === +id)?.score || 0
}

function isGroupOpen(id: number) {
  return openPanel.value.includes(id)
}

function toggle(id: number) {
  if (isGroupOpen(id)) {
    openPanel.value = openPanel.value.filter(i => +i !== +id)
  }
  else {
    openPanel.value.push(id)
  }
}

function finish(id: number) {
  if (getStatus(id) !== 2) {
    return
  }
  switch (id) {
    case 1:
      openModal('chooseProvider')
      break
    case 2:
      router.push('/faucet')
      break
    case 3:
      router.push('/portfolio/deposit')
      break
    case 4:
      router.push('/trade')
      break
    case 5:
      router.push('/pools')
      break
    case 6:
      router.push('/portfolio/pool')
      break
    case 7:
      router.push('/portfolio/withdraw')
      break
    case 8:
      router.push('/portfolio/claim')
      break
    default:
      break
  }
}

function initOpenStatus() {
  if (hasInitStatus.value || !interactionStatus.value.length) {
    return
  }
  interactionStatus.value.forEach((item) => {
    // if (getStatus(item.id) === 2 && !openPanel.value.includes(item.groupId)) {
    openPanel.value.push(item.groupId)
    // }
  })
  hasInitStatus.value = true
}

const currentScore = computed(() => {
  return interactionStatus.value.filter(i => i.completed).reduce((res, i) => +res + +i.score, 0)
})
const totalScore = computed(() => {
  return interactionStatus.value.reduce((res, i) => +res + +i.score, 0)
})

watch(interactionStatus, initOpenStatus)

onMounted(() => {
  initOpenStatus()
})
</script>v

<template>
  <div>
    <div class="flex items-center justify-between">
      <div class="max-w-2.8 sm:max-w-none">
        <span class="text-0.18 text-grey3 font-600">New to Vessel</span>
        <div class="text-0.14 text-grey1 font-500">
          Start your trading with Vessel, from zero to hero! Sub-quest will auto refresh upon quest completion.
        </div>
      </div>
      <span class="flex items-center gap-x-0.04 text-0.18 text-primary font-600">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g filter="url(#filter0_i_20731_51730)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9987 4.41555C9.9987 5.99055 11.982 6.68222 12.9654 5.45722C13.1904 5.18222 13.582 5.14055 13.857 5.34888C15.8654 6.79055 17.0487 9.37388 16.5404 12.0989C16.3932 12.8836 16.1018 13.6198 15.6963 14.2807C15.3095 14.9112 14.2241 14.1931 14.0904 13.4656C13.9404 12.6072 13.4737 11.8406 12.857 11.2322L10.582 8.99055C10.257 8.67388 9.74036 8.67388 9.41536 8.99055L7.15703 11.2156C6.5237 11.8322 6.05703 12.6156 5.8987 13.4822C5.8014 14.0126 5.81099 14.557 5.9269 15.0836C6.00632 15.4445 5.56498 15.8236 5.3024 15.5636C4.08569 14.359 3.33203 12.685 3.33203 10.8322C3.33203 6.64055 6.76536 3.74055 8.70703 2.44055C9.25703 2.07388 9.9987 2.47388 9.9987 3.14055V4.41555ZM8.30809 12.4152C9.24575 11.4914 10.7561 11.4974 11.6914 12.4235C12.0831 12.7985 12.3748 13.2818 12.4664 13.8152C12.7164 15.3402 11.5164 16.6652 9.99976 16.6652C8.48309 16.6652 7.28309 15.3402 7.53309 13.8068C7.62476 13.2735 7.92476 12.7985 8.30809 12.4152Z" fill="url(#paint0_linear_20731_51730)" />
          </g>
          <defs>
            <filter id="filter0_i_20731_51730" x="3.33203" y="2.30124" width="15.8262" height="19.3639" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dx="2.5" dy="5" />
              <feGaussianBlur stdDeviation="7.5" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.8007 0 0 0 0 0.988735 0 0 0 0 1 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_20731_51730" />
            </filter>
            <linearGradient id="paint0_linear_20731_51730" x1="9.99504" y1="2.30124" x2="9.99504" y2="16.6651" gradientUnits="userSpaceOnUse">
              <stop stop-color="#37AAEC" />
              <stop offset="1" stop-color="#19F2FF" />
            </linearGradient>
          </defs>
        </svg>
        {{ currentScore }}/{{ totalScore }}
      </span>
    </div>
    <div class="mt-0.14 overflow-hidden py-0.12">
      <div class="flex items-center">
        <div class="lock-bg h-0.4 w-0.4 flex-center rd-full">
          <AirdropInteractionIcon :status="getIconStatus(1)" />
          <div class="line-down" />
        </div>
        <div class="line h-0.02 w-0.3 pt-0.02" />
        <AirdropCard class="flex-1 select-none text-0.14 font-500 py-0.32!">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-0.18 text-white2 font-600">
                All Aboard!
              </div>
              <div v-if="isGroupOpen(1)" class="text-grey1">
                Sail with Vessel into the ocean of DeFi.
              </div>
            </div>
            <svgBack class="cursor-pointer" :class="isGroupOpen(1) ? 'rotate-90' : 'rotate--90'" @click="toggle(1)" />
          </div>
          <div v-if="isGroupOpen(1)">
            <AirdropInteractionItem title="Connect Wallet" :score="getScore(1)" :status="getStatus(1)" @click="finish(1)" />
            <AirdropInteractionItem title="Mint Test Token" :score="getScore(2)" :status="getStatus(2)" @click="finish(2)" />
            <AirdropInteractionItem title="Deposit" :score="getScore(3)" :status="getStatus(3)" @click="finish(3)" />
          </div>
        </AirdropCard>
      </div>
    </div>
    <div class="overflow-hidden py-0.12">
      <div class="flex items-center">
        <div class="lock-bg h-0.4 w-0.4 flex-center rd-full">
          <AirdropInteractionIcon :status="getIconStatus(2)" />

          <div class="line-up" />
          <div class="line-down" />
        </div>
        <div class="line h-0.02 w-0.3 pt-0.02" :class="{ done: getIconStatus(1) === 1 }" />
        <AirdropCard class="flex-1 select-none text-0.14 font-500 py-0.32!">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-0.18 text-white2 font-600">
                Trade your waves
              </div>
              <div v-if="isGroupOpen(2)" class="text-grey1">
                Ride your wave in crypto and earn Vessel points when trading orders are filled.
              </div>
            </div>
            <svgBack class="cursor-pointer" :class="isGroupOpen(2) ? 'rotate-90' : 'rotate--90'" @click="toggle(2)" />
          </div>
          <div v-if="isGroupOpen(2)">
            <AirdropInteractionItem title="Place Order" :score="getScore(4)" :status="getStatus(4)" @click="finish(4)" />
          </div>
        </AirdropCard>
      </div>
    </div>
    <div class="overflow-hidden py-0.12">
      <div class="flex items-center">
        <div class="lock-bg h-0.4 w-0.4 flex-center rd-full">
          <AirdropInteractionIcon :status="getIconStatus(3)" />

          <div class="line-up" />
          <div class="line-down" />
        </div>
        <div class="line h-0.02 w-0.3 pt-0.02" :class="{ done: getIconStatus(1) === 1 }" />
        <AirdropCard class="flex-1 select-none text-0.14 font-500 py-0.32!">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-0.18 text-white2 font-600">
                Dive into Liquidity
              </div>
              <div v-if="isGroupOpen(3)" class="text-grey1">
                Boost your DeFi experience by adding liquidity to Vessel's AMM pools.
              </div>
            </div>
            <svgBack class="cursor-pointer" :class="isGroupOpen(3) ? 'rotate-90' : 'rotate--90'" @click="toggle(3)" />
          </div>
          <div v-if="isGroupOpen(3)">
            <AirdropInteractionItem title="Add Liquidity" :score="getScore(5)" :status="getStatus(5)" @click="finish(5)" />
            <AirdropInteractionItem title="Remove Liquidity" :score="getScore(6)" :status="getStatus(6)" @click="finish(6)" />
          </div>
        </AirdropCard>
      </div>
    </div>
    <div class="mb-0.2 overflow-hidden py-0.12">
      <div class="flex items-center">
        <div class="lock-bg h-0.4 w-0.4 flex-center rd-full">
          <AirdropInteractionIcon :status="getIconStatus(4)" />
          <div class="line-up" />
        </div>
        <div class="line h-0.02 w-0.3 pt-0.02" />
        <AirdropCard class="flex-1 select-none text-0.14 font-500 py-0.32!">
          <div class="flex items-center justify-between">
            <div>
              <div class="text-0.18 text-white2 font-600">
                Docking your Vessel
              </div>
              <div v-if="isGroupOpen(4)" class="text-grey1">
                Withdraw your assets through a secure claiming process
              </div>
            </div>
            <svgBack class="cursor-pointer" :class="isGroupOpen(4) ? 'rotate-90' : 'rotate--90'" @click="toggle(4)" />
          </div>
          <div v-if="isGroupOpen(4)">
            <AirdropInteractionItem title="Withdraw" :score="getScore(7)" :status="getStatus(7)" @click="finish(7)" />
            <AirdropInteractionItem title="Claim" :score="getScore(8)" :status="getStatus(8)" @click="finish(8)" />
          </div>
        </AirdropCard>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.lock-bg {
  background: linear-gradient(180deg, rgba(105, 111, 125, 0.15) 6.37%, rgba(124, 128, 141, 0.30) 89.88%);
  box-shadow: 0px -0.4px 0.4px #7ACEFD;
  backdrop-filter: blur(6px);
  position: relative;
  .line-down {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(22rem / 400));
    height: 2rem;
    width: 2px;
    padding-left: 2px;
    background: var(--vessel-color-black-3);
  }
  .line-up {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, calc(-22rem / 400));
    height: 2rem;
    width: 2px;
    padding-left: 2px;
    background: var(--vessel-color-black-3);
  }
}

.line {
  background: linear-gradient(90deg, #23262F 0%, rgba(119, 126, 144, 0.00) 101.67%);
  &.done {
    background: linear-gradient(90deg, #37AAEC 0%, rgba(55, 170, 236, 0.00) 70.65%);
  }
}
</style>
