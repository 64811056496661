<script setup lang="ts">
const { isConnected, address } = useUser()
const { isSigned } = useKey()
const { openModal } = useModal()
const { initWallet, checkChain } = useConnector()
const { isMobile } = useResponsive()

const router = useRouter()

function openDepositModal() {

}

async function onConnectWallet() {
  openModal('chooseProvider')
}
// onMounted(initWallet)
</script>

<template>
  <div class="h-0.88 flex flex-none items-center justify-between px-0.2 sm:h-0.8">
    <div class="flex items-center">
      <div class="flex items-center" @click="openUrl(VESSEL_LANDING_PAGE)">
        <svg-logo-image mr-0.08 />
        <svg-logo-title />
        <div v-if="!IS_MAINNET" class="ml-0.04 rd-full bg-primary px-0.06 py-0.04 text-0.12 text-black1 font-700 leading-0.12">
          Test
        </div>
      </div>
      <div v-if="!isMobile" class="mx-0.32 h-0.4 bg-black2 pl-0.01" />
      <div v-if="!isMobile">
        <topbar-route />
      </div>
    </div>
    <div v-if="isConnected" class="flex items-center gap-x-0.12 sm:gap-x-0.24">
      <div
        class="flex cursor-pointer items-center gap-x-0.04 text-0.14 text-grey1 font-700"
        @click="openUrl('https://vesselfinance.gitbook.io/vessel')"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M4.99935 14.6666C4.3549 14.6666 3.8049 14.4389 3.34935 13.9833C2.89379 13.5278 2.66602 12.9778 2.66602 12.3333V3.66665C2.66602 3.0222 2.89379 2.4722 3.34935 2.01665C3.8049 1.56109 4.3549 1.33331 4.99935 1.33331H11.9993C12.366 1.33331 12.68 1.46376 12.9413 1.72465C13.2027 1.98554 13.3331 2.29954 13.3327 2.66665V11.0166C13.3327 11.1055 13.2967 11.1862 13.2247 11.2586C13.1527 11.3311 13.022 11.4115 12.8327 11.5C12.6771 11.5778 12.5549 11.6889 12.466 11.8333C12.3771 11.9778 12.3327 12.1444 12.3327 12.3333C12.3327 12.5222 12.3771 12.6918 12.466 12.842C12.5549 12.9922 12.6771 13.1004 12.8327 13.1666C12.9882 13.2333 13.1105 13.3251 13.1993 13.442C13.2882 13.5589 13.3327 13.6838 13.3327 13.8166V13.9833C13.3327 14.1722 13.2687 14.3333 13.1407 14.4666C13.0127 14.6 12.8545 14.6666 12.666 14.6666H4.99935ZM5.99935 9.99998C6.18824 9.99998 6.34668 9.93598 6.47468 9.80798C6.60268 9.67998 6.66646 9.52176 6.66602 9.33331V3.33331C6.66602 3.14442 6.60202 2.9862 6.47402 2.85865C6.34602 2.73109 6.18779 2.66709 5.99935 2.66665C5.81046 2.66665 5.65224 2.73065 5.52468 2.85865C5.39713 2.98665 5.33313 3.14487 5.33268 3.33331V9.33331C5.33268 9.5222 5.39668 9.68065 5.52468 9.80865C5.65268 9.93665 5.8109 10.0004 5.99935 9.99998ZM4.99935 13.3333H11.216C11.1494 13.1778 11.0967 13.0195 11.058 12.8586C11.0193 12.6978 10.9998 12.5226 10.9993 12.3333C10.9993 12.1555 11.016 11.9833 11.0493 11.8166C11.0827 11.65 11.1382 11.4889 11.216 11.3333H4.99935C4.71046 11.3333 4.47157 11.4306 4.28268 11.6253C4.09379 11.82 3.99935 12.056 3.99935 12.3333C3.99935 12.6222 4.09379 12.8611 4.28268 13.05C4.47157 13.2389 4.71046 13.3333 4.99935 13.3333Z" fill="#777E91" />
        </svg>
        <span class="font-dm">Guide</span>
      </div>
      <v-button v-if="isSigned" @click="router.push('/portfolio/deposit')">
        <template #prefix>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none" class="mr-0.12">
            <path d="M1.33333 14C0.966668 14 0.652668 13.8693 0.391334 13.608C0.130001 13.3467 -0.000443313 13.0329 1.13186e-06 12.6667V6C1.13186e-06 5.63334 0.130668 5.31934 0.392001 5.058C0.653334 4.79667 0.967112 4.66622 1.33333 4.66667H3.33333C3.52222 4.66667 3.68067 4.73067 3.80867 4.85867C3.93667 4.98667 4.00045 5.14489 4 5.33334C4 5.52222 3.936 5.68067 3.808 5.80867C3.68 5.93667 3.52178 6.00045 3.33333 6H1.33333V12.6667H10.6667V6H8.66667C8.47778 6 8.31933 5.936 8.19133 5.808C8.06333 5.68 7.99956 5.52178 8 5.33334C8 5.14445 8.064 4.986 8.192 4.858C8.32 4.73 8.47822 4.66622 8.66667 4.66667H10.6667C11.0333 4.66667 11.3473 4.79734 11.6087 5.05867C11.87 5.32 12.0004 5.63378 12 6V12.6667C12 13.0333 11.8693 13.3473 11.608 13.6087C11.3467 13.87 11.0329 14.0004 10.6667 14H1.33333ZM6.66667 8.11667L7.26667 7.51667C7.38889 7.39445 7.54178 7.33334 7.72534 7.33334C7.90889 7.33334 8.06711 7.4 8.2 7.53334C8.32222 7.65556 8.38334 7.81111 8.38334 8C8.38334 8.18889 8.32222 8.34445 8.2 8.46667L6.46667 10.2C6.33333 10.3333 6.17778 10.4 6 10.4C5.82222 10.4 5.66667 10.3333 5.53333 10.2L3.8 8.46667C3.67778 8.34445 3.614 8.19178 3.60867 8.00867C3.60333 7.82556 3.66711 7.66711 3.8 7.53334C3.92222 7.41111 4.07778 7.35 4.26667 7.35C4.45556 7.35 4.61111 7.41111 4.73334 7.53334L5.33333 8.11667V0.666669C5.33333 0.47778 5.39734 0.319336 5.52533 0.191336C5.65333 0.0633356 5.81156 -0.000442138 6 2.30681e-06C6.18889 2.30681e-06 6.34733 0.0640023 6.47534 0.192002C6.60333 0.320002 6.66711 0.478225 6.66667 0.666669V8.11667Z" fill="#FCFCFD" />
          </svg>
        </template>
        Deposit
      </v-button>
      <topbar-user v-if="!isMobile" />
      <topbar-question v-if="!isMobile" />
      <topbar-mobile-menu v-else />
    </div>
    <div v-else-if="!isMobile" class="flex items-center gap-x-0.24">
      <div
        class="flex cursor-pointer items-center gap-x-0.04 text-0.14 text-grey1 font-700"
        @click="openUrl('https://vesselfinance.gitbook.io/vessel')"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M4.99935 14.6666C4.3549 14.6666 3.8049 14.4389 3.34935 13.9833C2.89379 13.5278 2.66602 12.9778 2.66602 12.3333V3.66665C2.66602 3.0222 2.89379 2.4722 3.34935 2.01665C3.8049 1.56109 4.3549 1.33331 4.99935 1.33331H11.9993C12.366 1.33331 12.68 1.46376 12.9413 1.72465C13.2027 1.98554 13.3331 2.29954 13.3327 2.66665V11.0166C13.3327 11.1055 13.2967 11.1862 13.2247 11.2586C13.1527 11.3311 13.022 11.4115 12.8327 11.5C12.6771 11.5778 12.5549 11.6889 12.466 11.8333C12.3771 11.9778 12.3327 12.1444 12.3327 12.3333C12.3327 12.5222 12.3771 12.6918 12.466 12.842C12.5549 12.9922 12.6771 13.1004 12.8327 13.1666C12.9882 13.2333 13.1105 13.3251 13.1993 13.442C13.2882 13.5589 13.3327 13.6838 13.3327 13.8166V13.9833C13.3327 14.1722 13.2687 14.3333 13.1407 14.4666C13.0127 14.6 12.8545 14.6666 12.666 14.6666H4.99935ZM5.99935 9.99998C6.18824 9.99998 6.34668 9.93598 6.47468 9.80798C6.60268 9.67998 6.66646 9.52176 6.66602 9.33331V3.33331C6.66602 3.14442 6.60202 2.9862 6.47402 2.85865C6.34602 2.73109 6.18779 2.66709 5.99935 2.66665C5.81046 2.66665 5.65224 2.73065 5.52468 2.85865C5.39713 2.98665 5.33313 3.14487 5.33268 3.33331V9.33331C5.33268 9.5222 5.39668 9.68065 5.52468 9.80865C5.65268 9.93665 5.8109 10.0004 5.99935 9.99998ZM4.99935 13.3333H11.216C11.1494 13.1778 11.0967 13.0195 11.058 12.8586C11.0193 12.6978 10.9998 12.5226 10.9993 12.3333C10.9993 12.1555 11.016 11.9833 11.0493 11.8166C11.0827 11.65 11.1382 11.4889 11.216 11.3333H4.99935C4.71046 11.3333 4.47157 11.4306 4.28268 11.6253C4.09379 11.82 3.99935 12.056 3.99935 12.3333C3.99935 12.6222 4.09379 12.8611 4.28268 13.05C4.47157 13.2389 4.71046 13.3333 4.99935 13.3333Z" fill="#777E91" />
        </svg>
        <span class="font-dm">Guide</span>
      </div>
      <v-button class="w-1.4" @click="onConnectWallet">
        Connect Wallet
      </v-button>
      <topbar-question />
    </div>
    <topbar-mobile-menu v-else />
  </div>
</template>

<style scoped>

</style>
