<script setup lang="ts">
const { openModal } = useModal()
const { assetBalance } = useWs()
const { currentSymbolItem } = useSymbol()
// const tabOptions = [
//   { value: 'auto', label: 'Auto-Invest' },
//   { value: 'buy', label: 'Buy with' },
// ]
// const currentTab = ref('auto')

const assetList = computed(() => {
  if (!currentSymbolItem.value || !assetBalance.value) {
    return []
  }
  return [
    { assetName: currentSymbolItem.value.base, available: assetBalance.value.find(i => i.assetName === currentSymbolItem.value.base)?.available || 0 },
    { assetName: currentSymbolItem.value.quote, available: assetBalance.value.find(i => i.assetName === currentSymbolItem.value.quote)?.available || 0 },
  ]
})
</script>

<template>
  <div class="flex flex-col overflow-hidden p-0.1">
    <div class="test-grey3 mb-0.08 text-0.12 font-600">
      Assets
    </div>
    <!-- <v-tabs v-model="currentTab" :options="tabOptions" /> -->
    <div class="mt-0.08 flex gap-x-0.04">
      <v-button bg="black-3" @click="$router.push('/portfolio/deposit')">
        Deposit
      </v-button>
      <v-button bg="black-3" @click="$router.push('/portfolio/withdraw')">
        Withdraw
      </v-button>
      <v-button bg="black-3" @click="$router.push('/portfolio/claim')">
        Claim
      </v-button>
    </div>
    <div class="mt-0.14 flex flex-1 flex-col gap-y-0.14 overflow-auto text-0.12 text-grey3 font-500">
      <div v-for="item in assetList" :key="item.assetName" class="flex justify-between">
        <div class="flex">
          <div class="mr-0.04">
            {{ item.assetName }}
          </div>
          <div class="text-grey1">
            Available
          </div>
        </div>
        <div>{{ item.available }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
