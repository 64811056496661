<script lang="tsx" setup>
import { useRouteQuery } from '@vueuse/router'
import type { SelectOption } from 'naive-ui'
import { formatUnits, parseUnits } from 'viem'
import { signMessage } from 'node_modules/viem/_types/experimental/erc7739/actions/signMessage'
import { useSignTypedData } from '@wagmi/vue'
import BN from 'bignumber.js'
import VIcon from '@/components/v/Icon.vue'
import type { AssetItem } from '~/service/modules/user'
import type { BalanceItem } from '~/store/ws'
import type { TabItem } from '~/components/v/Tabs.vue'

const { modal, closeModal } = useModal()

const { assetBalance: _assetBalance } = useWs()
const { assetList, address } = useUser()
const notification = useNotification()
const { currencyList, fastWithdrawAssetList, fastWithdrawBalanceList, getFastWithdrawBalance } = useSymbol()
const message = useMessage()
const { currentChainId, currentVaultAddress, checkChain } = useConnector()
const { signTypedDataAsync } = useSignTypedData()
const router = useRouter()

const current = ref()
const amount = ref('')
const isChecking = ref(false)
const assetId = useRouteQuery('assetId')
const symbol = useRouteQuery('symbol')
const currentTab = ref('fast')
const isConfirm = ref(false)
const isSuccess = ref(false)
const isWithdrawing = ref(false)
const successSeconds = ref(3)

const tabList: TabItem[] = [
  {
    value: 'fast',
    label: 'Fast',
  },
  {
    value: 'standard',
    label: 'Standard',
  },
]

const currentName = computed(() => currencyList.value.find(i => i.assetId === `${current.value}`)?.assetName)
// const assetBalance = computed<AssetItem[] | BalanceItem[]>(() => _assetBalance.value.length ? _assetBalance.value : assetList.value)
const assetBalance = computed<AssetItem[]>(() => assetList.value)
const currentItem = computed(() => currencyList.value.find(i => i.assetName === currentName.value))
const currentBalance = computed(() => assetBalance.value.find(i => i.assetName === currentItem.value?.assetName))
const currentFastWithdrawItem = computed(() => fastWithdrawAssetList.value.find(i => `${i.assetId}` === `${current.value}`))
const maxBalance = computed(() => +fastWithdrawBalanceList.value.find(i => i.assetAddress.toLowerCase() === currentFastWithdrawItem.value.assetAddress.toLowerCase())?.balance || 0)
const isOverMaxAmount = computed(() => currentTab.value === 'fast' && amount.value && +amount.value > +maxBalance.value)

const { run: callWithdraw, isLoading } = useHttp(vesselApi.user.withdraw)

function goBack() {
  if (history.length) {
    router.back()
  }
  else {
    router.replace('/portfolio/spot')
  }
}

function minus(a: number | string, b: number | string) {
  return BN(a).minus(BN(b)).toString()
}

function toMaxAmount() {
  if (currentTab.value === 'standard') {
    amount.value = `${currentBalance.value.available}`
  }
  else {
    amount.value = `${Math.min(+currentBalance.value.available, +currentFastWithdrawItem.value.maxWithdrawAmount)}`
  }
}

function onChangeCurrency() {
  amount.value = ''
  getFastWithdrawBalance()
}

function onChangeTab(value: string) {
  currentTab.value = value
  amount.value = ''
  current.value = currencyList.value.find(i => i.assetName === 'USDT')?.assetId
}

const errorMsg = computed(() => {
  if (currentTab.value !== 'standard' || !amount.value) {
    return ''
  }
  const item = currencyList.value.find(i => i.assetName === currentBalance.value.assetName)
  if (!item) {
    return ''
  }
  if (+amount.value < +item.minWithdrawSize) {
    return (`Minimum withdrawal for ${item.assetName} is ${item.minWithdrawSize}`)
  }
  if (+amount.value > +item.maxWithdrawSize) {
    return `Maximum withdrawal for ${item.assetName} is ${item.maxWithdrawSize}`
  }
  if (+amount.value > +currentBalance.value?.available) {
    return 'Insufficient balance for withdrawal.'
  }
})

const errorMsgFastWithdraw = computed(() => {
  if (currentTab.value !== 'fast' || !amount.value) {
    return ''
  }
  const item = currencyList.value.find(i => i.assetName === currentBalance.value.assetName)
  if (!item) {
    return ''
  }

  const reg = new RegExp(`^\\d+(\\.\\d{1,${item.onChainDecimal}})?$`)
  if (+amount.value > +currentBalance.value?.available) {
    return 'Insufficient balance for withdrawal.'
  }
  if (+amount.value < +currentFastWithdrawItem.value.fee) {
    return (`Minimum withdrawal for ${item.assetName} is ${currentFastWithdrawItem.value.fee}`)
  }
  if (+amount.value > +currentFastWithdrawItem.value.maxWithdrawAmount) {
    return (`Maximum withdrawal for ${item.assetName} is ${currentFastWithdrawItem.value.maxWithdrawAmount}`)
  }
  if (+amount.value < +item.minWithdrawSize) {
    return (`Minimum withdrawal for ${item.assetName} is ${item.minWithdrawSize}`)
  }
  if (+amount.value > +item.maxWithdrawSize) {
    return `Maximum withdrawal for ${item.assetName} is ${item.maxWithdrawSize}`
  }
})
const disabled = computed(() => !+amount.value || isOverMaxAmount.value
  || (currentTab.value === 'fast' && errorMsgFastWithdraw.value)
  || (currentTab.value === 'standard' && errorMsg.value))

async function withdraw() {
  isChecking.value = true

  if (disabled.value || errorMsg.value) {
    return
  }

  isWithdrawing.value = true
  callWithdraw({
    assetName: currentBalance.value.assetName,
    amount: +amount.value,
    clientOrderId: `${new Date().getTime()}`,
  }).then((res) => {
    if (res.data.error) {
      return
    }
    // notification.success({
    //   title: 'Withdraw',
    //   content: `Withdraw request successfully. This record can be seen in history. You can claim it when status becomes success`,
    //   duration: 2000,
    // })
    isSuccess.value = true
    setInterval(() => {
      successSeconds.value -= 1
    }, 1000)
  }).finally(() => {
    isWithdrawing.value = false
  })
}

function renderLabel(option: SelectOption) {
  const assetName = currencyList.value.find(i => `${i.assetId}` === `${option.assetId}`)?.assetName
  return (
    <div class="flex items-center justify-start">
      <VIcon currency={assetName as string} class="mr-0.08 h-0.24 w-0.24" />
      {assetName}
    </div>
  )
}

function onConfirm() {
  if (!disabled.value) {
    isConfirm.value = true
  }
}

function onSearchAsset(filter: string, option: any) {
  const assetName = currencyList.value.find(i => `${i.assetId}` === `${option.assetId}`)?.assetName
  return assetName.toLowerCase().includes(filter.toLowerCase())
}

async function fastWithdraw() {
  if (disabled.value || errorMsgFastWithdraw.value) {
    return
  }
  const isRightChain = await checkChain()
  if (!isRightChain) {
    return
  }
  const msgParams = {
    domain: {
      name: 'vessel',
      chainId: currentChainId.value,
      version: '1.0',
      verifyingContract: currentVaultAddress.value,
    },
    message: {
      action: 'FAST_WITHDRAW',
      recipientAddress: address.value.toLowerCase(),
      assetAddress: currentFastWithdrawItem.value.assetAddress.toLowerCase(),
      assetAmount: parseUnits(`${+amount.value}`, +currentItem.value.onChainDecimal).toString(),
      feeAmount: parseUnits(currentFastWithdrawItem.value.fee, +currentItem.value.onChainDecimal).toString(),
      nonce: new Date().getTime(),
      expireTimestampSec: Math.floor(Date.now() / 1000) + 60 * 60 * 2,
    },
    primaryType: 'FastWithdraw',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
      ],
      FastWithdraw: [
        { name: 'action', type: 'string' },
        { name: 'recipientAddress', type: 'address' },
        { name: 'assetAddress', type: 'address' },
        { name: 'assetAmount', type: 'uint256' },
        { name: 'feeAmount', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'expireTimestampSec', type: 'uint256' },
      ],
    },
  }
  isWithdrawing.value = true
  try {
    const signature: any = await signTypedDataAsync(msgParams)
    vesselApi.user.fastWithdraw({
      signMessage: JSON.stringify(msgParams),
      signature,
    }).then((res) => {
      isSuccess.value = true
      setInterval(() => {
        successSeconds.value -= 1
      }, 1000)
    }).finally(() => {
      isWithdrawing.value = false
    })
  }
  catch (error) {
    console.log(error)
  }
  finally {
    isWithdrawing.value = false
  }
}

watch(successSeconds, (val) => {
  if (val === 0) {
    goBack()
  }
})

function clickWithdraw() {
  if (isWithdrawing.value) {
    return
  }
  if (currentTab.value === 'fast') {
    fastWithdraw()
  }
  else {
    withdraw()
  }
}

onMounted(() => {
  getFastWithdrawBalance()
  isChecking.value = false
  amount.value = ''
  if (assetId.value) {
    current.value = assetId.value
  }
  else if (symbol.value) {
    const pairs = getCurrencyFromSymbol(symbol.value as string)
    current.value = pairs.baseAssetId
  }
  else if (!current.value) {
    current.value = currencyList.value.find(i => i.assetName === 'USDT')?.assetId
  }
})
</script>

<template>
  <div class="absolute inset-0 bg-black1">
    <div class="mx-auto w-6.4">
      <div class="flex items-center text-caption1 text-grey1">
        <v-back v-if="!isSuccess" name="Back" class="text-caption1" @click="goBack" />
      </div>
      <v-tabs
        v-if="!isSuccess"
        v-model="currentTab"
        :disabled="isConfirm" :options="tabList" size="underline" class="mt-0.14" @change="onChangeTab"
      />
      <div v-if="!isSuccess" class="my-0.44 text-center text-headline4">
        {{ currentTab === 'fast' ? 'Fast' : 'Standard' }} Withdraw {{ isConfirm ? 'Confirm' : '' }}
      </div>
      <div v-else class="flex flex-col items-center justify-center">
        <svg-success class="mb-0.12 h-0.54 w-0.54" fill="#58bd7d" />
        <div class="flex items-center gap-x-0.08 text-body1b text-white1">
          <VIcon :currency="currentName" class="h-0.24 w-0.24" />
          <span v-if="currentTab === 'fast'">{{ minus(amount, currentFastWithdrawItem?.fee) }}</span>
          <span v-else>{{ amount }}</span>
          {{ currentName }}
        </div>
        <div class="mb-0.48 mt-0.04 text-caption2b text-grey1">
          {{ currentTab === 'fast' ? 'Fast Withdraw' : 'Standard Withdraw' }} Submitted
        </div>
      </div>
      <div v-if="!isConfirm">
        <!-- {{ currentFastWithdrawItem }} -->
        <div class="mb-0.12 ml-0.12 text-body2 text-grey2">
          Select Asset
        </div>
        <n-select
          v-model:value="current"
          :render-label="renderLabel"
          :options="currentTab === 'fast' ? fastWithdrawAssetList : currencyList"
          filterable :filter="onSearchAsset"
          value-field="assetId"
          @update:value="onChangeCurrency"
        />
        <div class="mb-0.12 ml-0.12 mt-0.32 text-body2 text-grey2">
          Amount
        </div>
        <v-input
          v-model="amount" align="left"
          :error-message="errorMsg || errorMsgFastWithdraw"
          message-placement="bottom"
          :precision="+currentItem?.depositPrecision"
        >
          <template #suffix>
            <v-button type="outline" size="tiny" class="text-caption2 px-0.08!" @click="toMaxAmount">
              MAX
            </v-button>
          </template>
        </v-input>
        <div v-if="isOverMaxAmount && !errorMsgFastWithdraw" class="mt-0.12 rd-0.12 bg-#ffd166/10 px-0.12 py-0.08">
          <div class="flex items-center gap-x-0.08">
            <svg-warning width="12" height="12" fill="#ffd166" />
            <div class="text-body2b text-yellow">
              {{ currentName }} fast withdraw pool liquidity is Scarce
            </div>
          </div>
          <div class="mt-0.04 text-caption2 text-yellow">
            You can <span class="cursor-pointer underline" @click="onChangeTab('standard')">standard withdraw</span>, fast withdraw smaller than {{ maxBalance }} {{ currentName }}, or retry in 1-2 hours.
          </div>
        </div>
        <div class="mx-0.12 mt-0.12 flex justify-between gap-x-0.04 text-0.12 text-grey1 font-600">
          <div>Available</div>
          <div class="flex gap-x-0.04 text-white2">
            {{ currentBalance?.available || '0' }} {{ currentName }}
          </div>
        </div>
        <div
          v-if="currentTab === 'fast'"
          class="mx-0.12 mt-0.12 flex justify-between gap-x-0.04 text-0.12 text-grey1 font-600"
        >
          <div class="flex">
            Max Withdraw <v-question>Maximum amount for a single fast withdraw.</v-question>
          </div>
          <div class="flex gap-x-0.04 text-white2">
            {{ currentFastWithdrawItem?.maxWithdrawAmount || '0' }} {{ currentName }}
          </div>
        </div>
        <div
          v-if="currentTab === 'fast'"
          class="mx-0.12 mt-0.12 flex justify-between gap-x-0.04 text-0.12 text-grey1 font-600"
        >
          <div class="flex">
            Fee
          </div>
          <div class="flex gap-x-0.04 text-white2">
            {{ currentFastWithdrawItem?.fee || '0' }} {{ currentName }}
          </div>
        </div>

        <v-button
          class="mt-0.32 w-full"
          :loading="isLoading"
          :disabled="disabled" @click="onConfirm"
        >
          Confirm Withdraw
        </v-button>
        <div v-if="currentTab === 'fast'" class="mt-0.32 rd-0.12 bg-black2 px-0.12 py-0.08 text-caption2 text-grey2">
          Fast Withdraw allows you to receive funds in your wallet instantly for a small fee, without waiting for blockchain confirmation or paying gas fee.
        </div>
        <div v-else class="mt-0.32 rd-0.12 bg-black2 px-0.12 py-0.08 text-caption2 text-grey2">
          Standard Withdrawals involve two steps:<br>
          1. Initiate Withdraw Request: Expect it to be processed on Layer 2 in about 30 minutes.<br>
          2. Claim to Your Wallet: After withdrawal success, claim the fund to your wallet.
        </div>
      </div>
      <div v-else>
        <div>
          <v-alert v-if="currentTab === 'standard'" type="success" class="mb-0.24 mt-0.32">
            Under standard withdraw, after the withdrawal is successful, please claim the funds to your wallet.
          </v-alert>
          <v-alert v-else-if="currentTab === 'fast'" type="success" class="mb-0.24 mt-0.32">
            Please double-check all information, as once it is submitted, it cannot be canceled.
          </v-alert>
          <div class="mb-0.08 flex justify-between">
            <div class="text-body2 text-grey2">
              Network
            </div>
            <div class="flex items-center gap-x-0.04 text-white1">
              <svg-scroll />
              Scroll
            </div>
          </div>
          <div class="mb-0.08 flex justify-between">
            <div class="text-body2 text-grey2">
              Select Asset
            </div>
            <div class="flex items-center gap-x-0.04 text-white1">
              <VIcon :currency="currentName" class="h-0.16 w-0.16" />
              {{ currentName }}
            </div>
          </div>
          <div class="mb-0.08 flex justify-between">
            <div class="text-body2 text-grey2">
              Address
            </div>
            <div class="flex items-center gap-x-0.04 text-white1">
              {{ address }}
            </div>
          </div>
          <div class="mb-0.08 flex justify-between">
            <div class="text-body2 text-grey2">
              Withdraw Amount
            </div>
            <div class="flex items-center gap-x-0.04 text-white1">
              {{ amount }} {{ currentName }}
            </div>
          </div>
          <div class="mb-0.08 flex justify-between">
            <div class="text-body2 text-grey2">
              Fee
            </div>
            <div v-if="currentTab === 'fast'" class="flex items-center gap-x-0.04 text-white1">
              {{ currentFastWithdrawItem?.fee || '0' }} {{ currentName }}
            </div>
            <div v-else class="flex items-center gap-x-0.04 text-white1">
              0 {{ currentName }}
            </div>
          </div>
          <!-- <div v-if="isSuccess" class="flex justify-between">
            <div class="text-body2 text-grey2">
              Receive Amount
            </div>
            <div class="flex items-center gap-x-0.04 text-white1">
              <span v-if="currentTab === 'fast'">{{ +amount - +currentFastWithdrawItem?.fee }}</span>
              <span v-else>{{ amount }}</span>
              {{ currentName }}
            </div>
          </div> -->

          <div class="mb-0.32 mt-0.24 bg-black3 pt-0.01" />
          <div v-if="!isSuccess" class="flex flex-col items-center justify-center">
            <div class="text-caption2b text-grey1">
              Receive Amount
            </div>
            <div class="flex items-center gap-x-0.04 text-headline4 text-white1 font-600">
              <VIcon :currency="currentName" class="h-0.24 w-0.24" />
              <span v-if="currentTab === 'fast'">{{ minus(amount, currentFastWithdrawItem?.fee) }}</span>
              <span v-else>{{ amount }}</span>
              {{ currentName }}
            </div>
          </div>
          <div v-if="!isSuccess" class="mt-0.48 flex items-center gap-x-0.32">
            <v-button
              v-if="!isWithdrawing" class="flex-1" type="outline"
              @click="isConfirm = false"
            >
              Previous
            </v-button>
            <v-button
              class="flex-1" :loading="isWithdrawing" :disabled="isWithdrawing"
              @click="clickWithdraw"
            >
              {{ currentTab === 'fast' ? 'Sign in Wallet' : 'Confirm Withdraw' }}
            </v-button>
          </div>
          <div v-else>
            <v-button
              class="mt-0.32 w-full"
              @click="goBack"
            >
              Back ({{ successSeconds }}s)
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
