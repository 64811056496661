<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import type { LiquidityItem } from '@/service/modules/amm'

const props = defineProps({
  poolId: {
    type: String,
    required: true,
  },
  currentPrice: {
    type: String,
    required: true,
  },
  initialMin: {
    type: Number,
    required: true,
  },
  initialMax: {
    type: Number,
    required: true,
  },
  width: {
    type: Number,
    default: 622,
  },
  height: {
    type: Number,
    default: 190,
  },
  isQuote: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['change'])

const { poolDetailList, updatePool } = useAmm()
const poolLiquidity = ref<LiquidityItem[]>([])

const currentPoolItem = computed(() => poolDetailList.value.find(i => i.poolId === props.poolId))
const { run: getLiquidity, isLoading: loadingLiquidity } = useHttp(vesselApi.amm.getPoolLiquidity)
const currentPrice = computed(() => props.isQuote ? +props.currentPrice : 1 / +props.currentPrice)
const pricePrecision = computed(() => props.isQuote ? getPrecisionFromSymbol(currentPoolItem.value?.symbol).price : 8 - getPrecisionFromSymbol(currentPoolItem.value?.symbol).price)

const poolLiquidityQuote = computed(() => props.isQuote
  ? poolLiquidity.value
  : [...poolLiquidity.value].reverse().map(i => ({
      startTick: 1 / +i.endTick,
      endTick: 1 / +i.startTick,
      baseTokenAmount: i.baseTokenAmount,
    })))

const totalRange = computed(() => {
  const allTicks = poolLiquidityQuote.value.reduce((acc, item) => {
    acc.push(Number(item.startTick), Number(item.endTick))
    return acc
  }, [])

  return {
    min: Math.min(...allTicks),
    max: Math.max(...allTicks),
  }
})

const leftValue = ref(props.initialMin)
const rightValue = ref(props.initialMax)
const minValue = ref(totalRange.value.min)
const maxValue = ref(totalRange.value.max)
const isDragging = ref(false)
const currentSlider = ref(null)
const startX = ref(0)
const startValue = ref(0)

const isViewDragging = ref(false)
const viewDragStart = ref(0)
const viewStartMin = ref(0)
const viewStartMax = ref(0)

function getMaxAmount() {
  return Math.max(...poolLiquidityQuote.value.map(item => Number.parseFloat(item.baseTokenAmount)))
}

function getPositionFromTick(tick) {
  const range = maxValue.value - minValue.value
  return ((tick - minValue.value) / range) * props.width
}

function getBarStyle(bar) {
  const left = getPositionFromTick(Number(bar.startTick))
  const width = getPositionFromTick(Number(bar.endTick)) - left
  const height = (Number.parseFloat(bar.baseTokenAmount) / getMaxAmount()) * (props.height - 30) // 减去标签的高度

  return {
    left: `${left - 0.1}px`,
    width: `${width + 0.2}px`,
    height: `${height}px`,
    bottom: 0,
  }
}

function getSliderPosition(value) {
  return getPositionFromTick(value)
}

function startDragging(slider, event) {
  isDragging.value = true
  currentSlider.value = slider
  startX.value = event.clientX
  startValue.value = slider === 'min' ? leftValue.value : rightValue.value
}

function handleMouseMove(event) {
  if (isDragging.value) {
    const dx = event.clientX - startX.value
    const range = maxValue.value - minValue.value
    const valueDelta = (dx / props.width) * range

    if (currentSlider.value === 'min') {
      const newMin = Math.max(
        minValue.value,
        startValue.value + valueDelta,
      )
      leftValue.value = newMin
      if (newMin > rightValue.value) {
        currentSlider.value = 'max'
      }
    }
    else {
      const newMax = Math.min(
        maxValue.value,
        startValue.value + valueDelta,
      )
      rightValue.value = newMax
      if (newMax < leftValue.value) {
        currentSlider.value = 'min'
      }
    }

    emit('change', { left: leftValue.value, right: rightValue.value })
  }
  else if (isViewDragging.value) {
    const dx = event.clientX - viewDragStart.value
    const range = maxValue.value - minValue.value
    const valueDelta = (dx / props.width) * range

    const newMin = viewStartMin.value - valueDelta
    const newMax = viewStartMax.value - valueDelta

    if (newMin >= totalRange.value.min * 0.9 && newMax <= totalRange.value.max * 1.1) {
      minValue.value = newMin
      maxValue.value = newMax
    }
  }
}

function handleMouseUp() {
  isDragging.value = false
  isViewDragging.value = false
  currentSlider.value = null
}

function startViewDragging(event) {
  if (event.target.closest('.slider'))
    return

  isViewDragging.value = true
  viewDragStart.value = event.clientX
  viewStartMin.value = minValue.value
  viewStartMax.value = maxValue.value
}

function handleWheel(event) {
  event.preventDefault()

  const rect = event.currentTarget.getBoundingClientRect()
  const mouseX = event.clientX - rect.left

  const range = maxValue.value - minValue.value
  const mouseTickValue = minValue.value + (mouseX / props.width) * range

  const zoomFactor = event.deltaY > 0 ? 1.1 : 0.9

  const currentRange = maxValue.value - minValue.value
  const newRange = Math.max(currentRange * zoomFactor, 10 * +currentPoolItem.value?.tickSpacing)

  const ratio = (mouseTickValue - minValue.value) / currentRange
  const newMin = mouseTickValue - newRange * ratio
  const newMax = mouseTickValue + newRange * (1 - ratio)

  minValue.value = Math.max(totalRange.value.min * 0.9, newMin)
  maxValue.value = Math.min(totalRange.value.max * 1.1, newMax)
  // minValue.value = Math.min(Math.max(totalRange.value.min * 0.9, newMin), leftValue.value * 0.99)
  // maxValue.value = Math.max(Math.min(totalRange.value.max * 1.1, newMax), rightValue.value * 1.01)
}

function formatToTick(tick) {
  const tickSpacing = props.isQuote ? +currentPoolItem.value?.tickSpacing : 1 / +currentPoolItem.value?.tickSpacing
  if (props.isQuote) {
    return +(Math.round(tick / tickSpacing * 1e8) * tickSpacing / 1e8).toFixed(pricePrecision.value)
  }
  return +(Math.round(tick / tickSpacing * 1e8) * tickSpacing / 1e8).toFixed(pricePrecision.value)
}

const leftPriceRatio = computed(() => getRatio((+leftValue.value - +currentPrice.value) / currentPrice.value, 2))
const rightPriceRatio = computed(() => getRatio((+rightValue.value - +currentPrice.value) / currentPrice.value, 2))

onMounted(() => {
  document.addEventListener('mousemove', handleMouseMove)
  document.addEventListener('mouseup', handleMouseUp)
})

onUnmounted(() => {
  document.removeEventListener('mousemove', handleMouseMove)
  document.removeEventListener('mouseup', handleMouseUp)
})

watch(computed(() => props.isQuote), (isQuote) => {
  minValue.value = totalRange.value.min * 0.9
  maxValue.value = totalRange.value.max * 1.1
}, {
  immediate: true,
})

watch(computed(() => [props.initialMin, props.initialMax]), ([min, max]) => {
  leftValue.value = min
  rightValue.value = max
  if (props.initialMin < minValue.value || props.initialMax > maxValue.value) {
    minValue.value = Math.min(minValue.value, min * 0.9)
    maxValue.value = Math.max(maxValue.value, max * 1.1)
  }
}, {
  immediate: true,
})

watch(computed(() => props.poolId), (poolId) => {
  if (!poolId)
    return

  getLiquidity(poolId).then((res) => {
    poolLiquidity.value = res.data.ticks.map(i => ({
      startTick: `${+i.startTick - +currentPoolItem.value?.tickSpacing / 2}`,
      endTick: `${+i.endTick + +currentPoolItem.value?.tickSpacing / 2}`,
      baseTokenAmount: i.baseTokenAmount,
    }))
    minValue.value = totalRange.value.min * 0.9
    maxValue.value = totalRange.value.max * 1.1
  })
}, {
  immediate: true,
})
</script>

<template>
  <div class="range-bar-chart">
    <div
      class="chart-area overflow-hidden"
      :style="{ width: `${props.width}px`, height: `${props.height}px` }"
      @mousedown="startViewDragging"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
      @wheel="handleWheel"
    >
      <div
        v-for="(bar, index) in poolLiquidityQuote"
        :key="index"
        class="bar-wrapper bg-#23262F"
      >
        <div
          class="bar z-1"
          :style="getBarStyle(bar)"
        />
      </div>
      <div

        class="bar-label flex select-none justify-between"
      >
        <div
          v-for="index in 5"
          :key="index"
          class="z-1"
        >
          {{ formatToTick(index * (maxValue - minValue) / 5 + minValue) }}
        </div>
      </div>

      <div
        class="slider left-slider"
        :style="{ left: `${getSliderPosition(leftValue)}px` }"
        @mousedown="(e) => startDragging('min', e)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="160" viewBox="0 0 10 160" fill="none" class="absolute z-2 -translate-x-4px">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V25C0 27.2091 1.79086 29 4 29H8V160H10V29V0H8H4Z" fill="#FFD87C" />
        </svg>
        <div class="absolute right-0.14 top-0 z-1 select-none rd-0.08 bg-#505768 px-0.12 py-0.06 text-right text-caption3m text-white2">
          {{ leftPriceRatio }}
        </div>
      </div>

      <div
        class="slider right-slider"
        :style="{ left: `${getSliderPosition(rightValue)}px` }"
        @mousedown="(e) => startDragging('max', e)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="160" viewBox="0 0 10 160" fill="none" class="absolute z-2">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C8.20914 0 10 1.79086 10 4V25C10 27.2091 8.20914 29 6 29H2V160H0V29V0H2H6Z" fill="#CDB4DB" />
        </svg>
        <div class="absolute left-0.14 top-0 z-1 select-none rd-0.08 bg-#505768 px-0.12 py-0.06 text-right text-caption3m text-white2">
          {{ rightPriceRatio }}
        </div>
      </div>

      <div
        class="absolute top-0 z-9 h-1.6 border-l-1 border-yellow border-dashed pl-0.01"
        :style="{ left: `${getSliderPosition(currentPrice)}px` }"
      />

      <div
        class="select-area absolute bottom-0.3 top-0" :style="{
          left: `${getSliderPosition(leftValue) + 4}px`,
          width: `${getSliderPosition(rightValue) - getSliderPosition(leftValue)}px`,
        }"
      />
    </div>
  </div>
</template>

<style scoped>
.range-bar-chart {
  position: relative;
}

.chart-container {
  position: relative;
}

.chart-area {
  border-radius: 12px;
  position: relative;
  background: rgb(20,20,22);
}

.bar-wrapper {
  position: absolute;
  width: 100%;
  height: calc(100% - 30px);
}

.bar {
  position: absolute;
  background: #37AAEC;
  transition: height 0.3s;
height: calc(100% - 30px);
}

.bar-label {
  position: absolute;
  font-size: 12px;
  text-align: center;
  color: #666;
  white-space: nowrap;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
}

@media (max-width: 600px) {
  .bar-label {
    writing-mode: vertical-rl;
    transform: translateX(-50%) rotate(180deg);
  }
}

.slider {
  position: absolute;
  width: 4px;
  height: 100%;
  cursor: ew-resize;
  z-index: 2;
}

.select-area {
  background: linear-gradient(90deg, #FFD87C 0%, #CDB4DB 100%);
  opacity: 0.08;

}
</style>
