export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index.vue'),
    children: [
      {
        path: 'explorer',
        name: '//explorer',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/explorer.vue'),
        /* no children */
      },
      {
        path: 'faucet',
        name: '//faucet',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/faucet.vue'),
        /* no children */
      },
      {
        path: 'key',
        name: '//key',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/key.vue'),
        /* no children */
      },
      {
        path: 'particle',
        name: '//particle',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/particle.vue'),
        /* no children */
      },
      {
        path: 'pools',
        /* internal name: '//pools' */
        /* no component */
        children: [
          {
            path: '',
            name: '//pools/',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/pools/index.vue'),
            /* no children */
          },
          {
            path: ':poolId',
            name: '//pools/[poolId]',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/pools/[poolId].vue'),
            /* no children */
          },
          {
            path: 'add',
            name: '//pools/add',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/pools/add.vue'),
            /* no children */
          },
          {
            path: 'increase',
            name: '//pools/increase',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/pools/increase.vue'),
            /* no children */
          },
          {
            path: 'remove',
            name: '//pools/remove',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/pools/remove.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'portfolio',
        name: '//portfolio',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/portfolio.vue'),
        children: [
          {
            path: 'claim',
            name: '//portfolio/claim',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/portfolio/claim.vue'),
            /* no children */
          },
          {
            path: 'deposit',
            name: '//portfolio/deposit',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/portfolio/deposit.vue'),
            /* no children */
          },
          {
            path: 'history',
            name: '//portfolio/history',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/portfolio/history.vue'),
            /* no children */
          },
          {
            path: 'open',
            name: '//portfolio/open',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/portfolio/open.vue'),
            /* no children */
          },
          {
            path: 'pool',
            name: '//portfolio/pool',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/portfolio/pool.vue'),
            /* no children */
          },
          {
            path: 'send',
            name: '//portfolio/send',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/portfolio/send.vue'),
            /* no children */
          },
          {
            path: 'spot',
            name: '//portfolio/spot',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/portfolio/spot.vue'),
            /* no children */
          },
          {
            path: 'withdraw',
            name: '//portfolio/withdraw',
            component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/portfolio/withdraw.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'setsail',
        name: '//setsail',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/setsail.vue'),
        /* no children */
      },
      {
        path: 'trade',
        name: '//trade',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/trade.vue'),
        /* no children */
      },
      {
        path: 'voyage',
        name: '//voyage',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/voyage.vue'),
        /* no children */
      },
      {
        path: 'wagmi',
        name: '//wagmi',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/index/wagmi.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/login.vue'),
    /* no children */
  },
  {
    path: '/test',
    /* internal name: '/test' */
    /* no component */
    children: [
      {
        path: ':name',
        name: '/test/[name]',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/test/[name].vue'),
        /* no children */
      },
      {
        path: 'input',
        name: '/test/input',
        component: () => import('/home/runner/_work/meex-frontend/meex-frontend/src/pages/test/input.vue'),
        /* no children */
      }
    ],
  }
]
