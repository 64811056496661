<script setup lang="ts">
import VTickSpacing from '~/components/v/TickSpacing.vue'

const { poolOverviewList, isLoadingOverviewList, getPoolOverviewList } = useAmm()
const router = useRouter()
const { isSigned } = useKey()
const { isConnected } = useUser()
const { openModal } = useModal()

const sortKey = ref('')
const sortOrder = ref<'asc' | 'desc'>('desc')
const searchValue = ref('')

function addSymbol(symbol: string, ts?: string) {
  if (!isConnected.value) {
    openModal('chooseProvider')
  }
  else if (!isSigned.value) {
    checkAndBindVesselKey()
  }
  else {
    router.push({
      path: '/pools/add',
      query: {
        symbol,
        ts,
      },
    })
  }
}

function jumpPoolOverview(poolId: string) {
  router.push({
    path: `/pools/${poolId}`,
  })
}

const poolOverviewListSort = computed(() => {
  return poolOverviewList.value.filter((i) => {
    const v = searchValue.value.replace(/\//g, '').toLowerCase()
    return `${i.baseTokenName}${i.quoteTokenName}`.toLowerCase().includes(v)
  }).sort((i, j) => {
    if (sortKey.value === 'tvl') {
      const r = +j.quoteTvlValue + +j.baseTvlValue - +i.quoteTvlValue - +i.baseTvlValue
      return sortOrder.value === 'asc' ? -r : r
    }
    else if (sortKey.value === 'volume') {
      const r = +j.tradeVolume24h - +i.tradeVolume24h
      return sortOrder.value === 'asc' ? -r : r
    }
    else if (sortKey.value === 'fee') {
      const r = +j.feeValue24h - +i.feeValue24h
      return sortOrder.value === 'asc' ? -r : r
    }
    else if (sortKey.value === 'apr') {
      const r = +j.apr - +i.apr
      return sortOrder.value === 'asc' ? -r : r
    }
    else if (sortKey.value === 'pool') {
      const r = `${j.baseTokenName}${j.quoteTokenName}`?.localeCompare(`${i.baseTokenName}${i.quoteTokenName}`)
      if (r === 0) {
        const rt = +j.tickSpacing - +i.tickSpacing
        return sortOrder.value === 'asc' ? -rt : rt
      }
      return sortOrder.value === 'asc' ? -r : r
    }
    else {
      const r = +j.quoteTvlValue + +j.baseTvlValue - +i.quoteTvlValue - +i.baseTvlValue
      return r
    }
  })
})

function changeSort(key: string) {
  if (sortKey.value !== key) {
    sortKey.value = key
    sortOrder.value = 'desc'
  }
  else {
    if (sortOrder.value === 'desc') {
      sortOrder.value = 'asc'
    }
    else {
      sortKey.value = ''
      sortOrder.value = ''
    }
  }
}

onMounted(() => {
  getPoolOverviewList()
})
</script>

<template>
  <div class="flex flex-col items-center px-0.8 py-0.4">
    <div class="text-0.48 text-white2 font-700 leading-.56 font-dm">
      Liquidity Pools
    </div>
    <div class="mt-0.12 text-center text-0.16 text-grey1 font-400">
      Become a liquidity provider to earn from protocol fees! Unlock bonus rewards during Vessel’s incentive phase!
    </div>
    <div class="mt-0.12 flex items-center gap-x-0.24">
      <v-button @click="addSymbol('')">
        <template #prefix>
          <SvgPlus class="mr-0.08" />
        </template>
        Create Position
      </v-button>
      <v-button type="outline" @click="$router.push('/portfolio/pool')">
        View My Position
      </v-button>
    </div>
    <div class="mt-0.24 min-h-4 w-full border-0.4 border-black3 rd-0.12 pb-0.32 pt-0.16 backdrop-blur-10px">
      <div class="mb-0.16 mr-0.24 flex justify-end">
        <v-input
          v-model="searchValue"
          class="w-1.55" placeholder="Search Pools"
          input-wrapper-class="bg-transparent!"
          align="left"
        >
          <template #suffix>
            <svg-search />
          </template>
        </v-input>
      </div>
      <div class="sm:px-0.12">
        <div class="mb-0.14 flex px-0.12 text-0.12 text-grey1 font-600">
          <div class="flex flex-grow-1 flex-basis-2 cursor-pointer select-none items-center" @click="changeSort('pool')">
            Pool
            <v-sort :order="sortKey === 'pool' ? sortOrder : null" />
          </div>
          <div class="flex flex-grow-1 flex-basis-1 cursor-pointer select-none items-center justify-end text-right" @click="changeSort('tvl')">
            TVL
            <v-sort :order="sortKey === 'tvl' ? sortOrder : null" />
          </div>
          <div class="flex flex-grow-1 flex-basis-1 cursor-pointer select-none items-center justify-end text-right" @click="changeSort('volume')">
            24h Volume
            <v-sort :order="sortKey === 'volume' ? sortOrder : null" />
          </div>
          <div class="flex flex-grow-1 flex-basis-1 cursor-pointer select-none items-center justify-end text-right" @click="changeSort('fee')">
            24h Fee
            <v-sort :order="sortKey === 'fee' ? sortOrder : null" />
          </div>
          <div class="flex flex-grow-1 flex-basis-1 cursor-pointer select-none items-center justify-end" @click="changeSort('apr')">
            Est.APR
            <n-tooltip>
              <div class="mr-0.04 w-2 text-0.12">
                Est. APR is based on the average APR over the last 24 hours’ fees and TVL. The actual APR may vary.
              </div>
              <template #trigger>
                <svg class="ml-0.04 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79948 13.3333C11.745 13.3333 14.1328 10.9455 14.1328 7.99998C14.1328 5.05446 11.745 2.66665 8.79948 2.66665C5.85396 2.66665 3.46615 5.05446 3.46615 7.99998C3.46615 10.9455 5.85396 13.3333 8.79948 13.3333ZM8.79948 14.6666C12.4814 14.6666 15.4661 11.6819 15.4661 7.99998C15.4661 4.31808 12.4814 1.33331 8.79948 1.33331C5.11758 1.33331 2.13281 4.31808 2.13281 7.99998C2.13281 11.6819 5.11758 14.6666 8.79948 14.6666Z" fill="#777E91" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79948 4.66666C8.43129 4.66666 8.13281 4.96513 8.13281 5.33332C8.13281 5.70151 8.43129 5.99999 8.79948 5.99999C9.16767 5.99999 9.46615 5.70151 9.46615 5.33332C9.46615 4.96513 9.16767 4.66666 8.79948 4.66666ZM8.79948 7.33332C8.43129 7.33332 8.13281 7.6318 8.13281 7.99999V10.6667C8.13281 11.0348 8.43129 11.3333 8.79948 11.3333C9.16767 11.3333 9.46615 11.0348 9.46615 10.6667V7.99999C9.46615 7.6318 9.16767 7.33332 8.79948 7.33332Z" fill="#777E91" />
                </svg>
              </template>
            </n-tooltip>
            <v-sort :order="sortKey === 'apr' ? sortOrder : null" />
          </div>
          <div class="flex-grow-1 flex-basis-1 text-right">
            Action
          </div>
        </div>
        <SvgLoading v-if="isLoadingOverviewList" class="mx-auto mt-1 animate-spin" />
        <div v-else-if="poolOverviewListSort.length === 0" class="mt-1 flex-center text-caption1">
          No pool found for "{{ searchValue }}". Please check the token symbols.
        </div>
        <div v-else>
          <div
            v-for="(item, index) in poolOverviewListSort" :key="index"
            class="mb-0.12 h-0.72 flex cursor-pointer rd-0.08 px-0.12 py-0.14 text-body2b text-white2 font-500 leading-0.48 hover:bg-black2"
            @click="jumpPoolOverview(item.poolId)"
          >
            <div class="flex flex-grow-1 flex-basis-2 items-center">
              <v-icon-pair class="mr-0.08" :base="item.baseTokenName" :quote="item.quoteTokenName" :size="24" />
              <div>
                <div class="text-caption1">
                  {{ item.baseTokenName }}/{{ item.quoteTokenName }}
                </div>
                <div class="flex items-center gap-x-0.04">
                  <VTickSpacing :tick-spacing="item.tickSpacing" :token="item.quoteTokenName" />
                  <div v-if="item.boost" class="h-0.2 flex items-center gap-x-0.02 rd-full bg-#37aaec/20 px-0.06 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <path d="M4.2994 1.60002C4.21512 1.53689 4.11306 1.50197 4.00777 1.50025C3.90248 1.49853 3.79933 1.5301 3.71304 1.59046C3.62675 1.65081 3.56171 1.73687 3.52721 1.83636C3.49271 1.93586 3.4905 2.0437 3.5209 2.14452C3.6083 2.43612 3.61812 2.74547 3.5494 3.04202C3.48091 3.33682 3.33606 3.60841 3.1294 3.82952C3.11563 3.8444 3.10277 3.86009 3.0909 3.87652C2.7929 4.28502 1.1109 6.67652 2.6204 9.25752L2.6354 9.28202C3.00208 9.83348 3.5043 10.2815 4.0939 10.583C4.67639 10.8813 5.32603 11.0241 5.9799 10.9975C6.67517 11.0097 7.36175 10.8417 7.9729 10.51C8.59468 10.1719 9.11833 9.67855 9.4929 9.07802C10.1434 7.97802 10.0849 6.80002 9.7869 5.85752C9.4954 4.93352 8.9469 4.15052 8.4834 3.80652C8.41058 3.7526 8.32447 3.71948 8.23428 3.7107C8.14409 3.70193 8.05322 3.71783 7.97136 3.7567C7.88951 3.79557 7.81976 3.85596 7.76957 3.9314C7.71937 4.00684 7.69063 4.09451 7.6864 4.18502C7.6529 4.90052 7.5049 5.46052 7.2894 5.90052C7.1784 4.69702 6.7259 3.80252 6.1774 3.13852C5.6039 2.44352 4.8954 1.98852 4.5159 1.74452C4.44254 1.69878 4.37051 1.65092 4.2999 1.60102L4.2994 1.60002Z" fill="url(#paint0_linear_32159_35455)" />
                      <defs>
                        <linearGradient id="paint0_linear_32159_35455" x1="6.00059" y1="1.50018" x2="6.00059" y2="15.1783" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#37AAEC" />
                          <stop offset="1" stop-color="#19F2FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <div class="text-hairline2">
                      {{ item.boost.boost }}x
                    </div>
                  </div>
                  <svg-scroll />
                </div>
              </div>
            </div>
            <div class="flex-grow-1 flex-basis-1 text-right">
              ${{ formatNumberWithUnit(+item.quoteTvlValue + +item.baseTvlValue) }}
            </div>
            <div class="flex-grow-1 flex-basis-1 text-right">
              ${{ formatNumberWithUnit(item.tradeVolume24h) }}
            </div>
            <div class="flex-grow-1 flex-basis-1 text-right">
              ${{ formatNumberWithUnit(item.feeValue24h) }}
            </div>
            <div class="flex-grow-1 flex-basis-1 text-right">
              {{ getRatio(item.apr) }}
            </div>
            <div
              class="flex flex-grow-1 flex-basis-1 cursor-pointer items-center justify-end text-right text-button2 text-primary"
              @click.stop="addSymbol(`${item.baseTokenName}${item.quoteTokenName}`, item.tickSpacing)"
            >
              + Add
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
