<script setup lang="ts">
const props = defineProps<{
  title?: string
  modalClass?: string
  titleClass?: string
  type?: string
  zIndex?: number
}>()
const emit = defineEmits(['close', 'open'])
const show = defineModel('show')
const { isMobile } = useResponsive()

function closeModal() {
  emit('close')
  show.value = false
}

whenever(show, () => {
  emit('open')
})
</script>

<template>
  <Teleport to="body">
    <div>
      <Transition name="wrapper">
        <div v-if="show" class="fixed left-0 top-0 z-100 h-full w-full bg-#141416/30 backdrop-blur-3px" />
      </Transition>
      <Transition :name="isMobile ? 'mobile-modal' : 'modal'">
        <div v-if="show" class="fixed bottom-0 left-0 z-200 w-full flex-center text-0.16 font-poppins sm:bottom-auto sm:top-0 sm:h-full" :style="{ zIndex: `${zIndex}` }">
          <div
            class="max-w-full flex flex-col justify-center rd-tl-0.2 rd-tr-0.2 bg-black2 p-0.16 pb-0.32 sm:max-w-auto sm:rd-0.2 sm:p-0.32"
            :class="modalClass"
          >
            <div class="mb-0.1 w-full flex items-center justify-between">
              <div class="flex items-center">
                <div>
                  <svg
                    v-if="type === 'error'" class="mr-0.04"
                    xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"
                  >
                    <path d="M10.268 21.7332C10.5124 21.9776 10.8235 22.0998 11.2013 22.0998C11.5791 22.0998 11.8902 21.9776 12.1346 21.7332L16.0013 17.8665L19.9013 21.7665C20.1457 22.011 20.4515 22.1278 20.8186 22.1172C21.1857 22.1065 21.4911 21.9785 21.7346 21.7332C21.9791 21.4887 22.1013 21.1776 22.1013 20.7998C22.1013 20.4221 21.9791 20.1109 21.7346 19.8665L17.868 15.9998L21.768 12.0998C22.0124 11.8554 22.1293 11.5496 22.1186 11.1825C22.108 10.8154 21.98 10.5101 21.7346 10.2665C21.4902 10.0221 21.1791 9.89984 20.8013 9.89984C20.4235 9.89984 20.1124 10.0221 19.868 10.2665L16.0013 14.1332L12.1013 10.2332C11.8569 9.98873 11.5515 9.87228 11.1853 9.88384C10.8191 9.89539 10.5133 10.0229 10.268 10.2665C10.0235 10.5109 9.9013 10.8221 9.9013 11.1998C9.9013 11.5776 10.0235 11.8887 10.268 12.1332L14.1346 15.9998L10.2346 19.8998C9.99019 20.1443 9.87375 20.4501 9.8853 20.8172C9.89686 21.1843 10.0244 21.4896 10.268 21.7332ZM16.0013 29.3332C14.1569 29.3332 12.4235 28.9829 10.8013 28.2825C9.17908 27.5821 7.76797 26.6323 6.56797 25.4332C5.36797 24.2332 4.41819 22.8221 3.71864 21.1998C3.01908 19.5776 2.66886 17.8443 2.66797 15.9998C2.66797 14.1554 3.01819 12.4221 3.71864 10.7998C4.41908 9.17762 5.36886 7.7665 6.56797 6.5665C7.76797 5.3665 9.17908 4.41673 10.8013 3.71717C12.4235 3.01762 14.1569 2.66739 16.0013 2.6665C17.8457 2.6665 19.5791 3.01673 21.2013 3.71717C22.8235 4.41762 24.2346 5.36739 25.4346 6.5665C26.6346 7.7665 27.5849 9.17762 28.2853 10.7998C28.9857 12.4221 29.3355 14.1554 29.3346 15.9998C29.3346 17.8443 28.9844 19.5776 28.284 21.1998C27.5835 22.8221 26.6337 24.2332 25.4346 25.4332C24.2346 26.6332 22.8235 27.5834 21.2013 28.2838C19.5791 28.9843 17.8457 29.3341 16.0013 29.3332Z" fill="#EF445B" />
                  </svg>
                </div>
                <slot name="title">
                  <div class="text-body1b text-white2">
                    <div :class="titleClass">
                      {{ title }}
                    </div>
                  </div>
                </slot>
              </div>
              <div class="ml-0.1 h-0.3 w-0.3 flex-center cursor-pointer border-2 border-black3 rd-full transition-all hover:rotate-90" @click="closeModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96967 3.96967C4.26256 3.67678 4.73744 3.67678 5.03033 3.96967L9 7.93934L12.9697 3.96967C13.2626 3.67678 13.7374 3.67678 14.0303 3.96967C14.3232 4.26256 14.3232 4.73744 14.0303 5.03033L10.0607 9L14.0303 12.9697C14.3232 13.2626 14.3232 13.7374 14.0303 14.0303C13.7374 14.3232 13.2626 14.3232 12.9697 14.0303L9 10.0607L5.03033 14.0303C4.73744 14.3232 4.26256 14.3232 3.96967 14.0303C3.67678 13.7374 3.67678 13.2626 3.96967 12.9697L7.93934 9L3.96967 5.03033C3.67678 4.73744 3.67678 4.26256 3.96967 3.96967Z" fill="#FCFCFD" />
                </svg>
              </div>
            </div>
            <slot />
            <slot name="footer" />
          </div>
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<style scoped>
.modal-enter-active,
.modal-leave-active {
  transition: all 0.2s ease;
  transform: scale(1);
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-modal-enter-active,
.mobile-modal-leave-active {
  transition: all 0.4s ease;
  transform: translate(0, 0);
}

.mobile-modal-enter-from,
.mobile-modal-leave-to {
  opacity: 0;
  transform: translate(0, 100%);
}
.wrapper-enter-active,
.wrapper-leave-active {
  transition: all 0.2s ease;
}

.wrapper-enter-from,
.wrapper-leave-to {
  opacity: 0;
}
</style>
