import { useRouteQuery } from '@vueuse/router'
import type { CurrencyItem, FastWithdrawAssetItem, FastWithdrawBalanceItem, FeeItem, SymbolItem, TickerSymbol } from '~/service/modules/public'

export const useSymbol = createGlobalState(() => {
  const currentSymbol = useStorage<string>('currentSymbol', null)
  const symbolQuery = useRouteQuery('symbol')

  const symbolList = ref<({ base: string, quote: string } & SymbolItem)[]>([])
  const currencyList = ref<CurrencyItem[]>([])
  const tickerInfo = ref<TickerSymbol[]>([])
  const feeInfo = ref<FeeItem[]>([])
  const fastWithdrawAssetList = ref<FastWithdrawAssetItem[]>([])
  const fastWithdrawBalanceList = ref<FastWithdrawBalanceItem[]>([])

  const currentSymbolItem = computed(() => symbolList.value.find(i => i.symbol === currentSymbol.value))
  const currentTickerItem = computed(() => tickerInfo.value.find(i => i.symbol === currentSymbol.value))

  vesselApi.public.getSymbols().then((res) => {
    symbolList.value = res.map(i => ({
      ...i,
      base: i.baseAssetName,
      quote: i.quoteAssetName,
    }))
    if (!currentSymbol.value || !symbolList.value.some(i => i.symbol === currentSymbol.value)) {
      currentSymbol.value = symbolList.value[0]?.symbol
    }
  })
  vesselApi.public.getCurrency().then((res) => {
    currencyList.value = res.data.assets
  })
  vesselApi.public.getTickerInfo().then((res) => {
    tickerInfo.value = res
  })
  vesselApi.public.getFeeInfo().then((res) => {
    feeInfo.value = res.data.vipFeeRateDetails
  })
  vesselApi.public.getFastWithdrawAsset().then((res) => {
    fastWithdrawAssetList.value = res.map(i => ({ ...i, disabled: !i.enabled }))
  })

  getFastWithdrawBalance()
  function getFastWithdrawBalance() {
    vesselApi.public.getFastWithdrawBalance().then((res) => {
      fastWithdrawBalanceList.value = res
    })
  }

  function changeCurrentSymbol(symbol: string) {
    currentSymbol.value = symbol
    symbolQuery.value = symbol
  }

  return {
    symbolList,
    currencyList,
    currentSymbol,
    currentSymbolItem,
    changeCurrentSymbol,
    currentTickerItem,
    tickerInfo,
    feeInfo,
    fastWithdrawAssetList,
    fastWithdrawBalanceList,
    getFastWithdrawBalance,
  }
})
