<script setup lang="ts">
export interface TabItem {
  value: string
  label: string
  disabled?: boolean
}
const props = withDefaults(defineProps<{
  options: TabItem[]
  size?: 'small' | 'default' | 'underline'
  disabled?: boolean
}>(), {
  size: 'default',
})

const emit = defineEmits(['change'])

const current = defineModel()

const customClass = computed(() => {
  const fontSize = props.size === 'small' ? `text-0.12 font-600` : 'text-0.14 font-700'
  return `${fontSize}`
})

function onChange(item: TabItem) {
  if (!props.disabled) {
    current.value = item.value
    emit('change', item.value)
  }
}
</script>

<template>
  <div class="flex select-none gap-x-0.16 font-dm" :class="customClass">
    <div
      v-for="item in options" :key="item.value"
      class="tab-item"
      :class="{
        'text-primary current': item.value === current,
        'text-grey1': item.value !== current,
        'text-grey1/80': item.disabled || disabled,
        'tab-underline': props.size === 'underline',
        'cursor-not-allowed': props.disabled,
        'cursor-pointer': !props.disabled,
      }"
      @click="onChange(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.tab-item {
  transition: all 0.2s ease-in;
}

.tab-underline {
  position: relative;
  height: 32px;
  line-height: 32px;
  &.current::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
    height: 4px;
    background: var(--vessel-color-primary);
  }
}
</style>
