<script setup lang="ts">
import BN from 'bignumber.js'
import { useRouteQuery } from '@vueuse/router'
import type { Column } from '~/components/v/Table.vue'
import type { AssetItem } from '@/service/modules/user'
import type { AvgCostItem } from '@/service/modules/stats'
import type { AssetItemWithClaimable } from '@/store/user'

const assetId = useRouteQuery('assetId')

const { assetList, assetListWithClaimable, isLoadingAsset, address, getUserAssetWithClaim } = useUser()
const { currencyList } = useSymbol()
const { isSigned } = useKey()
const { isMobile } = useResponsive()
const { openModal } = useModal()
const { allMintList } = useFaucet()
const showAssetDetail = ref(false)
const isGetClaim = ref(false)
const currentAsset = ref<AssetItemWithClaimable>()
const avgCostList = ref<AvgCostItem[]>([])

const { run: getAvgCost } = useHttp(vesselApi.stats.getAvgCost)

const tableColumns: Column[] = [
  {
    key: 'assetName',
    title: 'Asset Name',
    width: 200,
    align: 'left',
  },
  {
    key: 'total',
    title: 'Total',
    width: 150,
    align: 'right',
  },
  {
    key: 'pnl',
    title: 'Cml PNL%',
    width: 150,
    align: 'right',
  },
  {
    key: 'available',
    title: 'Available',
    width: 150,
    align: 'right',
  },
  {
    key: 'inUse',
    title: 'In Use',
    width: 150,
    align: 'right',
  },
  {
    key: 'pendingWithdraw',
    title: 'Pending Withdraw',
    width: 150,
    align: 'right',
  },
  {
    key: 'claimable',
    title: 'Claimable',
    width: 150,
    align: 'right',
  },
]

const tableColumnsMobile: Column[] = [
  {
    key: 'assetName',
    title: 'Asset Name',
    minWidth: 80,
    align: 'left',
  },
  {
    key: 'total',
    title: 'Total',
    minWidth: 150,
    align: 'right',
  },
  {
    key: 'detail',
    title: '',
    minWidth: 20,
    align: 'right',
  },
]

function handleClickRow(row: AssetItem) {
  showAssetDetail.value = true
  currentAsset.value = row
}

function openCurrent(type: 'deposit' | 'withdraw' | 'claim') {
  showAssetDetail.value = false
  assetId.value = `${currencyList.value.find(i => i.assetName === currentAsset.value.assetName)?.assetId}`
  setTimeout(() => {
    openModal(type)
  }, 50)
}

function getFullName(assetName: string) {
  const item = allMintList.value.find(i => i.name === assetName)
  return item?.fullName || assetName
}

function getAmount(...amount: number[]) {
  return amount.reduce((res, i) => res.plus(BN(i)), BN(0)).toNumber()
}

function getPrice(price: number, ...amount: number[]) {
  return BN(price).multipliedBy(amount.reduce((res, i) => res.plus(BN(i)), BN(0))).toNumber()
}

function getPnl(row: AssetItemWithClaimable) {
  const currency = currencyList.value?.find(i => i.assetName === row.assetName)
  const avgItem = avgCostList.value?.find(i => i.tokenAddress.toLowerCase() === currency.address.toLowerCase())
  if (currency && avgItem) {
    return (+row.price - +avgItem.avgCostPrice) * getAmount(+row.available, +row.inUse)
  }
  return ''
}

function getPnlRatio(row: AssetItemWithClaimable) {
  const currency = currencyList.value?.find(i => i.assetName === row.assetName)
  const avgItem = avgCostList.value?.find(i => i.tokenAddress.toLowerCase() === currency.address.toLowerCase())
  if (currency && avgItem) {
    return getRatio((+row.price - +avgItem.avgCostPrice) / +avgItem.avgCostPrice)
  }
  return ''
}

// watch(assetList, async () => {
//   assetListWithClaimable.value = assetList.value.map(i => ({ ...i, claimable: 0 }))
//   for (const item in assetListWithClaimable.value) {
//     assetListWithClaimable.value[item] = {
//       ...assetListWithClaimable.value[item],
//       claimable: await getClaimable(assetListWithClaimable.value[item].assetName),
//     }
//   }
// })

watch(assetList, () => {
  if (assetList.value.length && !isGetClaim.value) {
    isGetClaim.value = true
    getUserAssetWithClaim()
  }
})

onMounted(() => {
  if (isSigned.value) {
    getAvgCost().then((res) => {
      avgCostList.value = res.data.costs
    })
  }
})
</script>

<template>
  <div class="h-full flex flex-col gap-0.02">
    <AssetModule v-if="!isMobile" class="flex flex-1 flex-col py-0.2">
      <div class="mb-0.08 flex-none px-0.32 text-0.14 text-grey3 font-500">
        Assets
      </div>
      <v-table
        class="min-h-3 px-0.04"
        :data="assetListWithClaimable"
        :columns="tableColumns"
        :loading="isLoadingAsset"
        :column-gap="35"
        row-class="h-0.8 py-0.14 px-0.32 gap-x-0.16 border-t-1 border-t-black2"
        title-row-class="py-0.14 px-0.32 gap-x-0.16"
        row-key="assetName"
      >
        <template #title-pnl>
          <div class="flex items-center justify-end">
            Cml PNL%
            <v-question>
              <div class="w-2">
                Cml PNL% = Cml PNL/Avg Cost * 100%<br>
                Avg Cost =  (Average cost before the last buy or deposit * amount + Price of the last buy or deposit * amount)/ Balance after the last buy or deposit
              </div>
            </v-question>
          </div>
        </template>
        <template #title-available>
          <div class="flex items-center justify-end">
            Available
            <v-question>
              Freely use for trading.
            </v-question>
          </div>
        </template>
        <template #title-inUse>
          <div class="flex items-center justify-end">
            In Use
            <v-question>
              In active trades or open orders.
            </v-question>
          </div>
        </template>
        <template #title-claimable>
          <div class="flex items-center justify-end">
            Claimable
            <v-question>
              Ready for you to transfer your assets to your wallet.
            </v-question>
          </div>
        </template>
        <template #title-pendingWithdraw>
          <div class="flex items-start justify-end">
            Pending Withdraw
            <v-question>
              <div class="w-3">
                Withdrawal requests have been submitted, and awaiting confirmation from the prover. Your assets are available in this state and will become 'Claimable' once confirmed
              </div>
            </v-question>
          </div>
        </template>
        <template #assetName="{ data: { row } }">
          <div class="w-auto flex items-center gap-x-0.12">
            <v-icon :currency="row.assetName" class="h-0.24 w-0.24" />
            <div>
              <div class="text-caption1b">
                {{ row.assetName }}
              </div>
              <div class="truncate text-caption1 text-grey1">
                {{ getFullName(row.assetName) }}
              </div>
            </div>
          </div>
        </template>
        <template #total="{ data: { row } }">
          <div class="mb-0.04 text-caption1b text-white1">
            {{ formatNumber(getAmount(+row.available, +row.inUse, +row.pendingWithdraw, +row.claimable), -1) }}
          </div>
          <div class="truncate text-caption1 text-grey1">
            ${{ formatNumber(getPrice(+row.price, +row.available, +row.inUse, +row.pendingWithdraw, +row.claimable), 2) }}
          </div>
        </template>
        <template #pnl="{ data: { row } }">
          <div v-if="getPnl(row) !== ''" class="truncate text-caption1b" :class="+getPnl(row) >= 0 ? 'text-green' : 'text-red'">
            {{ getPnlRatio(row) }}
          </div>
          <div v-if="getPnl(row) !== ''" class="mb-0.04 text-caption1" :class="+getPnl(row) >= 0 ? 'text-green' : 'text-red'">
            {{ +getPnl(row) < 0 ? '-' : '' }}${{ formatNumber(Math.abs(+getPnl(row)), 2) }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #available="{ data: { row } }">
          <div class="mb-0.04 text-caption1b text-white1">
            {{ formatNumber(getAmount(+row.available), -1) }}
          </div>
          <div class="truncate text-caption1 text-grey1">
            ${{ formatNumber(getPrice(+row.price, +row.available), 2) }}
          </div>
        </template>
        <template #inUse="{ data: { row } }">
          <div class="mb-0.04 text-caption1b text-white1">
            {{ formatNumber(getAmount(+row.inUse), -1) }}
          </div>
          <div class="truncate text-caption1 text-grey1">
            ${{ formatNumber(getPrice(+row.price, +row.inUse), 2) }}
          </div>
        </template>
        <template #pendingWithdraw="{ data: { row } }">
          <div class="mb-0.04 text-caption1b text-white1">
            {{ formatNumber(getAmount(+row.pendingWithdraw), -1) }}
          </div>
          <div class="truncate text-caption1 text-grey1">
            ${{ formatNumber(getPrice(+row.price, +row.pendingWithdraw), 2) }}
          </div>
        </template>
        <template #claimable="{ data: { row } }">
          <div class="mb-0.04 text-caption1b text-white1">
            {{ formatNumber(getAmount(row.claimable), -1) }}
          </div>
          <div class="truncate text-caption1 text-grey1">
            ${{ formatNumber(getPrice(+row.price, row.claimable), 2) }}
          </div>
        </template>
      </v-table>
    </AssetModule>
    <AssetModule v-if="isMobile">
      <div class="flex flex-none justify-between px-0.2 py-0.16 text-0.14 text-grey3 font-500">
        <v-button @click="openModal('deposit')">
          Deposit
        </v-button>
        <v-button bg="black-3" color="primary" @click="$router.push('/portfolio/withdraw')">
          Withdraw
        </v-button>
        <v-button bg="black-3" color="primary" @click="$router.push('/portfolio/claim')">
          Claim
        </v-button>
      </div>
    </AssetModule>
    <AssetModule v-if="isMobile" class="flex flex-1 flex-col py-0.16">
      <v-table
        class="min-h-1"
        :data="assetListWithClaimable"
        :columns="tableColumnsMobile"
        :loading="isLoadingAsset"
        :column-gap="35"
        row-class="h-0.8 py-0.14 px-0.32 gap-x-0.16 border-t-1 border-t-black3"
        title-row-class="py-0.14 px-0.32 gap-x-0.16"
        row-key="assetName"
        @click-row="handleClickRow"
      >
        <template #total="{ data: { row } }">
          <div>{{ BN(row.available).plus(BN(row.inUse)).plus(BN(row.pendingWithdraw)).plus(BN(row.claimable)) }}</div>
          <div class="text-grey1">
            $ {{ BN(row.available).plus(BN(row.inUse)).plus(BN(row.pendingWithdraw)).plus(BN(row.claimable)).multipliedBy(BN(row.price)).toFixed(2) }}
          </div>
        </template>
        <template #detail>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <g clip-path="url(#clip0_13202_10603)">
              <path d="M13.3335 25.3333C13.3335 26.8 14.5335 28 16.0002 28C17.4668 28 18.6668 26.8 18.6668 25.3333C18.6668 23.8667 17.4668 22.6667 16.0002 22.6667C14.5335 22.6667 13.3335 23.8667 13.3335 25.3333ZM13.3335 6.66667C13.3335 8.13333 14.5335 9.33333 16.0002 9.33333C17.4668 9.33333 18.6668 8.13333 18.6668 6.66667C18.6668 5.2 17.4668 4 16.0002 4C14.5335 4 13.3335 5.2 13.3335 6.66667ZM13.3335 16C13.3335 17.4667 14.5335 18.6667 16.0002 18.6667C17.4668 18.6667 18.6668 17.4667 18.6668 16C18.6668 14.5333 17.4668 13.3333 16.0002 13.3333C14.5335 13.3333 13.3335 14.5333 13.3335 16Z" fill="#777E91" />
            </g>
            <defs>
              <clipPath id="clip0_13202_10603">
                <rect width="32" height="32" fill="white" transform="matrix(0 -1 1 0 0 32)" />
              </clipPath>
            </defs>
          </svg>
        </template>
      </v-table>
    </AssetModule>
    <v-modal
      v-if="currentAsset"
      v-model:show="showAssetDetail"
      modal-class="text-grey1 w-full"
      :z-index="199"
      :title="currentAsset?.assetName"
    >
      <div class="flex flex-col gap-y-0.14">
        <div class="flex items-start justify-between text-right">
          <div>Total</div>
          <div>
            <div class="text-white2">
              {{ BN(currentAsset.available).plus(BN(currentAsset.inUse)).plus(BN(currentAsset.pendingWithdraw)).plus(BN(currentAsset.claimable)) }}
            </div>
            <div class="text-grey1">
              $ {{ BN(currentAsset.available).plus(BN(currentAsset.inUse)).plus(BN(currentAsset.pendingWithdraw)).plus(BN(currentAsset.claimable)).multipliedBy(BN(currentAsset.price)) }}
            </div>
          </div>
        </div>
        <div class="flex items-start justify-between text-right">
          <div>Available</div>
          <div>
            <div class="text-white2">
              {{ BN(currentAsset.available) }}
            </div>
            <div class="text-grey1">
              $ {{ BN(currentAsset.available).multipliedBy(BN(currentAsset.price)) }}
            </div>
          </div>
        </div>
        <div class="flex items-start justify-between text-right">
          <div>In Use</div>
          <div>
            <div class="text-white2">
              {{ BN(currentAsset.inUse) }}
            </div>
            <div class="text-grey1">
              $ {{ BN(currentAsset.inUse).multipliedBy(BN(currentAsset.price)) }}
            </div>
          </div>
        </div>
        <div class="flex items-start justify-between text-right">
          <div>Pending Withdraw</div>
          <div>
            <div class="text-white2">
              {{ BN(currentAsset.pendingWithdraw) }}
            </div>
            <div class="text-grey1">
              $ {{ BN(currentAsset.pendingWithdraw).multipliedBy(BN(currentAsset.price)) }}
            </div>
          </div>
        </div>
        <div class="flex items-start justify-between text-right">
          <div>Claimable</div>
          <div>
            <div class="text-white2">
              {{ BN(currentAsset.claimable) }}
            </div>
            <div class="text-grey1">
              $ {{ BN(currentAsset.claimable).multipliedBy(BN(currentAsset.price)) }}
            </div>
          </div>
        </div>
        <div class="flex flex-none justify-between px-0.2 py-0.16 text-0.14 text-grey3 font-500">
          <v-button @click="openCurrent('deposit')">
            Deposit
          </v-button>
          <v-button bg="black-3" color="primary" @click="openCurrent('withdraw')">
            Withdraw
          </v-button>
          <v-button bg="black-3" color="primary" @click="openCurrent('claim')">
            Claim
          </v-button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<style scoped>

</style>
