<template>
  <n-tooltip>
    <div class="text-0.12">
      Scroll Marks
    </div>
    <template #trigger>
      <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_35801_59851)">
          <path d="M32 0H0V32H32V0Z" fill="#FFEEDA" />
          <path d="M24.0302 18.8854V7.97369C24.0158 7.05838 23.2879 6.32324 22.3726 6.32324H10.9852C8.52751 6.35928 6.55273 8.37009 6.55273 10.835C6.55273 11.6638 6.77616 12.3773 7.1149 13.0043C7.40318 13.5232 7.85724 14.0133 8.30409 14.3809C8.43382 14.4818 8.36895 14.4386 8.75814 14.6764C9.29147 15.0007 9.90409 15.1665 9.90409 15.1665L9.89688 21.6962C9.91129 22.0061 9.94012 22.3016 10.0266 22.5827C10.2789 23.5052 10.9203 24.2115 11.7852 24.5503C12.1455 24.6944 12.5563 24.7881 12.996 24.7953L22.0915 24.8241C23.9005 24.8241 25.3708 23.3539 25.3708 21.5377C25.378 20.4566 24.8374 19.4908 24.0302 18.8854Z" fill="#FFEEDA" />
          <path d="M24.2543 21.6169C24.2183 22.7773 23.2597 23.7142 22.085 23.7142L15.8291 23.6926C16.3264 23.116 16.6291 22.3665 16.6291 21.5449C16.6291 20.262 15.8651 19.3755 15.8651 19.3755H22.0922C23.2886 19.3755 24.2615 20.3485 24.2615 21.5449L24.2543 21.6169Z" fill="#EBC28E" />
          <path d="M8.90228 13.4614C8.18156 12.7767 7.67706 11.8975 7.67706 10.8452V10.7371C7.73472 8.94971 9.20499 7.50827 10.9924 7.45782H22.3798C22.6753 7.47223 22.9131 7.68124 22.9131 7.98395V17.62C23.1726 17.6632 23.3023 17.6993 23.5545 17.793C23.7563 17.865 24.0302 18.0236 24.0302 18.0236V7.98395C24.0158 7.06863 23.2879 6.3335 22.3726 6.3335H10.9852C8.52751 6.36953 6.55273 8.38034 6.55273 10.8452C6.55273 12.2794 7.20859 13.5047 8.27526 14.3623C8.34733 14.42 8.4194 14.4993 8.60679 14.4993C8.93832 14.4993 9.17616 14.2326 9.16174 13.9443C9.16174 13.6993 9.05364 13.6128 8.90228 13.4614Z" fill="#101010" />
          <path d="M22.0858 18.2508H13.1561C12.5579 18.258 12.075 18.7409 12.075 19.3391V20.622C12.0894 21.213 12.5939 21.7175 13.1993 21.7175H13.8624V20.622H13.1921V19.368C13.1921 19.368 13.3579 19.368 13.5525 19.368C14.684 19.368 15.5128 20.413 15.5128 21.5373C15.5128 22.5319 14.6047 23.8004 13.0912 23.6995C11.7507 23.613 11.0227 22.4166 11.0227 21.5373V10.6544C11.0227 10.1643 10.6191 9.76074 10.129 9.76074H9.23535V10.8779H9.89841V21.5445C9.86238 23.7139 11.4408 24.8022 13.0912 24.8022L22.093 24.831C23.902 24.831 25.3723 23.3607 25.3723 21.5445C25.3795 19.7283 23.902 18.2508 22.0858 18.2508ZM24.2552 21.6166C24.2191 22.777 23.2606 23.7139 22.0858 23.7139L15.8299 23.6923C16.3272 23.1157 16.6299 22.3661 16.6299 21.5445C16.6299 20.2616 15.866 19.3752 15.866 19.3752H22.093C23.2894 19.3752 24.2624 20.3481 24.2624 21.5445L24.2552 21.6166Z" fill="#101010" />
          <path d="M19.6749 11.0756H12.9434V9.9585H19.6749C19.9776 9.9585 20.2298 10.2035 20.2298 10.5135C20.2371 10.8234 19.992 11.0756 19.6749 11.0756Z" fill="#101010" />
          <path d="M19.6749 16.3184H12.9434V15.2085H19.6749C19.9776 15.2085 20.2298 15.4535 20.2298 15.7635C20.2371 16.0662 19.992 16.3184 19.6749 16.3184Z" fill="#101010" />
          <path d="M20.8641 13.7006H12.9434V12.5835H20.8569C21.1596 12.5835 21.4118 12.8285 21.4118 13.1385C21.4262 13.4484 21.174 13.7006 20.8641 13.7006Z" fill="#101010" />
        </g>
        <defs>
          <clipPath id="clip0_35801_59851">
            <rect width="32" height="32" rx="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </template>
  </n-tooltip>
</template>
