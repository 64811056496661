<script setup lang="tsx">
import type { DropdownOption } from 'naive-ui'

const { openModal, feedbackType } = useModal()

const options = computed<DropdownOption[]>(() => [
  {
    label: 'Share Feedback',
    key: 'feedback',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E91">
        <path d="M1.33398 14.6677V2.66768C1.33398 2.30102 1.46465 1.98724 1.72598 1.72635C1.98732 1.46546 2.3011 1.3348 2.66732 1.33435H13.334C13.7007 1.33435 14.0147 1.46502 14.276 1.72635C14.5373 1.98768 14.6678 2.30146 14.6673 2.66768V10.6677C14.6673 11.0344 14.5369 11.3484 14.276 11.6097C14.0151 11.871 13.7011 12.0015 13.334 12.001H4.00065L1.33398 14.6677ZM8.00065 10.001C8.18954 10.001 8.34798 9.93702 8.47598 9.80902C8.60398 9.68102 8.66776 9.52279 8.66732 9.33435C8.66732 9.14546 8.60332 8.98724 8.47532 8.85968C8.34732 8.73213 8.1891 8.66813 8.00065 8.66768C7.81176 8.66768 7.65354 8.73168 7.52598 8.85968C7.39843 8.98768 7.33443 9.14591 7.33398 9.33435C7.33398 9.52324 7.39798 9.68168 7.52598 9.80968C7.65398 9.93768 7.81221 10.0015 8.00065 10.001ZM7.33398 7.33435H8.66732V3.33435H7.33398V7.33435Z" fill="inherit" />
      </svg>
    ),
  },
  {
    label: 'Report a Bug',
    key: 'bug',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E91">
        <path d="M7.99935 14.001C7.27713 14.001 6.60779 13.8232 5.99135 13.4676C5.3749 13.1121 4.88868 12.6232 4.53268 12.001H2.66602V10.6676H4.06602C4.03268 10.4454 4.01313 10.2232 4.00735 10.001C4.00157 9.77875 3.9989 9.55653 3.99935 9.33431H2.66602V8.00098H3.99935C3.99935 7.77875 4.00202 7.55653 4.00735 7.33431C4.01268 7.11209 4.03224 6.88987 4.06602 6.66764H2.66602V5.33431H4.53268C4.68824 5.07875 4.86335 4.83987 5.05802 4.61764C5.25268 4.39542 5.47757 4.20098 5.73268 4.03431L4.66602 2.93431L5.59935 2.00098L7.03268 3.43431C7.34379 3.33431 7.66046 3.28431 7.98268 3.28431C8.3049 3.28431 8.62157 3.33431 8.93268 3.43431L10.3993 2.00098L11.3327 2.93431L10.2327 4.03431C10.4882 4.20098 10.7189 4.39253 10.9247 4.60898C11.1305 4.82542 11.3109 5.0672 11.466 5.33431H13.3327V6.66764H11.9327C11.966 6.88987 11.9856 7.11209 11.9914 7.33431C11.9971 7.55653 11.9998 7.77875 11.9993 8.00098H13.3327V9.33431H11.9993C11.9993 9.55653 11.9965 9.77875 11.9907 10.001C11.9849 10.2232 11.9656 10.4454 11.9327 10.6676H13.3327V12.001H11.466C11.1105 12.6232 10.6245 13.1121 10.008 13.4676C9.39157 13.8232 8.72202 14.001 7.99935 14.001ZM6.66602 10.6676H9.33268V9.33431H6.66602V10.6676ZM6.66602 8.00098H9.33268V6.66764H6.66602V8.00098Z" fill="inherit" />
      </svg>
    ),
  },
  {
    label: 'Discord',
    key: 'discord',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E91">
        <path d="M12.8464 3.5532C11.9598 3.13987 10.9998 2.83987 9.99975 2.66653C9.99098 2.66625 9.98226 2.6679 9.9742 2.67136C9.96614 2.67481 9.95893 2.67999 9.95309 2.68653C9.83309 2.90653 9.69309 3.1932 9.59975 3.4132C8.53909 3.2532 7.46042 3.2532 6.39975 3.4132C6.30642 3.18653 6.16642 2.90653 6.03975 2.68653C6.03309 2.6732 6.01309 2.66653 5.99309 2.66653C4.99309 2.83987 4.03975 3.13987 3.14642 3.5532C3.13975 3.5532 3.13309 3.55987 3.12642 3.56653C1.31309 6.27987 0.813086 8.91987 1.05975 11.5332C1.05975 11.5465 1.06642 11.5599 1.07975 11.5665C2.27975 12.4465 3.43309 12.9799 4.57309 13.3332C4.59309 13.3399 4.61309 13.3332 4.61975 13.3199C4.88642 12.9532 5.12642 12.5665 5.33309 12.1599C5.34642 12.1332 5.33309 12.1065 5.30642 12.0999C4.92642 11.9532 4.56642 11.7799 4.21309 11.5799C4.18642 11.5665 4.18642 11.5265 4.20642 11.5065C4.27975 11.4532 4.35309 11.3932 4.42642 11.3399C4.43975 11.3265 4.45975 11.3265 4.47309 11.3332C6.76642 12.3799 9.23975 12.3799 11.5064 11.3332C11.5198 11.3265 11.5398 11.3265 11.5531 11.3399C11.6264 11.3999 11.6998 11.4532 11.7731 11.5132C11.7998 11.5332 11.7998 11.5732 11.7664 11.5865C11.4198 11.7932 11.0531 11.9599 10.6731 12.1065C10.6464 12.1132 10.6398 12.1465 10.6464 12.1665C10.8598 12.5732 11.0998 12.9599 11.3598 13.3265C11.3798 13.3332 11.3998 13.3399 11.4198 13.3332C12.5664 12.9799 13.7198 12.4465 14.9198 11.5665C14.9331 11.5599 14.9398 11.5465 14.9398 11.5332C15.2331 8.5132 14.4531 5.8932 12.8731 3.56653C12.8664 3.55987 12.8598 3.5532 12.8464 3.5532ZM5.67975 9.93987C4.99309 9.93987 4.41975 9.30653 4.41975 8.52653C4.41975 7.74653 4.97975 7.1132 5.67975 7.1132C6.38642 7.1132 6.94642 7.7532 6.93975 8.52653C6.93975 9.30653 6.37975 9.93987 5.67975 9.93987ZM10.3264 9.93987C9.63975 9.93987 9.06642 9.30653 9.06642 8.52653C9.06642 7.74653 9.62642 7.1132 10.3264 7.1132C11.0331 7.1132 11.5931 7.7532 11.5864 8.52653C11.5864 9.30653 11.0331 9.93987 10.3264 9.93987Z" fill="inherit" />
      </svg>
    ),
  },
  {
    label: 'Telegram',
    key: 'telegram',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E91">
        <path d="M13.8068 2.4373C13.8068 2.4373 15.1018 1.9323 14.9934 3.15863C14.9578 3.66363 14.6341 5.4313 14.3821 7.34296L13.5188 13.0063C13.5188 13.0063 13.4468 13.836 12.7991 13.9803C12.1518 14.1243 11.1804 13.4753 11.0004 13.331C10.8564 13.2226 8.30243 11.5993 7.4031 10.806C7.1511 10.5893 6.8631 10.1566 7.4391 9.65163L11.2164 6.0443C11.6481 5.61096 12.0798 4.60096 10.2811 5.82763L5.24443 9.2543C5.24443 9.2543 4.66877 9.6153 3.58977 9.29063L1.2511 8.56896C1.2511 8.56896 0.387765 8.02796 1.86276 7.48696C5.46043 5.79163 9.88543 4.0603 13.8064 2.43696" fill="inherit" />
      </svg>
    ),
  },

  {
    label: 'Twitter',
    key: 'twitter',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E91">
        <g clip-path="url(#clip0_35422_54227)">
          <path d="M9.6642 6.77226L15.4895 0.000831604H14.1091L9.05099 5.88037L5.0111 0.000831604H0.351562L6.46067 8.89173L0.351562 15.9926H1.73205L7.07353 9.78363L11.34 15.9926H15.9995L9.66386 6.77226H9.6642ZM7.77344 8.97006L7.15446 8.08473L2.22946 1.04004H4.3498L8.32433 6.72533L8.94331 7.61066L14.1097 15.0007H11.9894L7.77344 8.9704V8.97006Z" fill="inherit" />
        </g>
        <defs>
          <clipPath id="clip0_35422_54227">
            <rect width="15.6479" height="16" fill="white" transform="translate(0.351562 0.00100708)" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    label: 'Contact',
    key: 'contact',
    suffix: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#777E91">
        <path d="M2.66732 13.3337C2.30065 13.3337 1.98687 13.2032 1.72598 12.9423C1.4651 12.6814 1.33443 12.3674 1.33398 12.0003V4.00033C1.33398 3.63366 1.46465 3.31988 1.72598 3.05899C1.98732 2.7981 2.3011 2.66744 2.66732 2.66699H13.334C13.7007 2.66699 14.0147 2.79766 14.276 3.05899C14.5373 3.32033 14.6678 3.6341 14.6673 4.00033V12.0003C14.6673 12.367 14.5369 12.681 14.276 12.9423C14.0151 13.2037 13.7011 13.3341 13.334 13.3337H2.66732ZM8.00065 8.66699L13.334 5.33366V4.00033L8.00065 7.33366L2.66732 4.00033V5.33366L8.00065 8.66699Z" fill="inherit" />
      </svg>
    ),
  },
])

function renderLabel(option: DropdownOption) {
  return (
    <div class="flex items-center justify-between gap-x-0.08">
      <div class="w-1.34 text-0.14 font-poppins">{option.label}</div>
      {h(option.suffix)}
    </div>
  )
}

function handleSelect(key: string) {
  switch (key) {
    case 'feedback':
      openModal('feedback')
      feedbackType.value = 'feedback'
      break
    case 'bug':
      openModal('feedback')
      feedbackType.value = 'bug'
      break
    case 'discord':
      openUrl(DISCORD_URL)
      break
    case 'telegram':
      openUrl(TELEGRAM_URL)
      break
    case 'twitter':
      openUrl('https://x.com/vesselfinance?s=21&t=EglxJCuyhz1aGUWyhMhHTg')
      break
    case 'contact':
      openUrl('mailto:gm@vessel.finance')
      break
  }
}
</script>

<template>
  <div>
    <n-dropdown
      class="border-none outline-none"
      :options="options"
      placement="bottom-end"
      size="large"
      :render-label="renderLabel"
      @select="handleSelect"
    >
      <svg class="cursor-pointer border-none outline-none" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9993 26.6666C21.8904 26.6666 26.666 21.891 26.666 16C26.666 10.1089 21.8904 5.33329 15.9993 5.33329C10.1083 5.33329 5.33268 10.1089 5.33268 16C5.33268 21.891 10.1083 26.6666 15.9993 26.6666ZM15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z" fill="#777E91" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8451 12.6677C14.4764 13.3051 13.6608 13.5229 13.0233 13.1542C12.3859 12.7855 12.1681 11.9698 12.5368 11.3324C13.2264 10.1403 14.5186 9.33337 16.0009 9.33337C18.21 9.33337 20.0009 11.1242 20.0009 13.3334C20.0009 14.797 19.103 15.992 18.0009 16.6667C17.1156 17.2087 17.6676 18.6667 15.9974 18.6667C15.261 18.6667 14.6641 18.0698 14.6641 17.3334C14.6641 17.2866 14.6665 17.2403 14.6712 17.1947C14.7082 16.4896 15.0226 15.9158 15.3649 15.4986C15.7265 15.058 16.2019 14.6983 16.5654 14.4822C18.5876 13.28 16.1211 10.8206 14.8451 12.6677ZM15.9974 20C15.261 20 14.6641 20.597 14.6641 21.3334C14.6641 22.0698 15.261 22.6667 15.9974 22.6667C16.7338 22.6667 17.3307 22.0698 17.3307 21.3334C17.3307 20.597 16.7338 20 15.9974 20Z" fill="#777E91" />
      </svg>
    </n-dropdown>
  </div>
</template>

<style scoped>

</style>
